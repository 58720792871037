export const fr = {
  login_sign_in: 'Se connecter',
  login_email_address: 'Adresse e-mail',
  login_form_password_label: 'Mot de passe',
  login_forget_password: 'Mot de passe oublié',
  login_credentials_error:
           'Veuillez vérifier votre e-mail et votre mot de passe',
  login_api_error: 'Alarme API de connexion interne',
  internal_server_error: 'Alarme Serveur Interne',
  /* Forget Password */
  forget_password_title: 'Mot de passe oublié',
  forget_password_email_address: 'Adresse e-mail',
  forget_password_button:
           'Envoyer le lien de réinitialisation du mot de passe',
  forget_password_login: 'Retour',
  forgot_password_form_success:
           "L'e-mail de réinitialisation du mot de passe a été envoyé. Veuillez vérifier votre boîte de réception. L'e-mail pourrait se trouver dans vos spams.",
  forgot_password_subject:
           'Fortes Data Management - Mot de passe oublié',
  temporary_password_subject:
           'Fortes Data Management - Nouveau compte créé',
  forgot_password_required_form_error:
           "L'adresse e-mail est obligatoire",
  forgot_password_valid_form_error:
           'Doit être une adresse e-mail valide',
  /* END Forget Password */
  /** Reset Password */
  reset_password_title: 'Réinitialiser le mot de passe',
  reset_password_new_password: 'Nouveau mot de passe',
  reset_password_confirm_password: 'Confirmer le mot de passe',
  reset_password_new_password_required_form_error:
           'Le nouveau mot de passe est requis',
  reset_password_confirm_password_required_form_error:
           'La confirmation du mot de passe est requise',
  reset_password_passwords_not_matching:
           'Les mots de passe ne correspondent pas',
  reset_password_new_password_form_error:
           'Le mot de passe doit contenir au moins 8 caractères, dont au moins une lettre majuscule, au moins un chiffre et au moins un caractère spécial.',
  reset_password_button: 'Réinitialiser le mot de passe',
  reset_password_form_success:
           'Votre mot de passe a été mis à jour avec succès.',
  /** End Reset Password */
  /* DASHBOARD */
  dashboard_title: 'Tableau de bord',
  dashboard_amount_of_devices: "NOMBRE D'APPAREILS",
  dashboard_amount_of_devices_week:
           "NOMBRE D'APPAREILS LA SEMAINE DERNIÈRE",
  dashboard_amount_of_devices_error:
           "NOMBRE D'APPAREILS AVEC DES ERREURS",
  dashboard_temperature: 'TEMPÉRATURE DE RETOUR EN DEGRÉS CELSIUS',
  dashboard_device_status: 'Connexions totales : ',
  dashboard_routing_temperature: 'Températures de tous les appareils',
  dashboard_routing_temperature_hr: 'Heure',
  dashboard_error_devices: 'Appareils dont le contrôle est défectueux :',
  dashboard_error: 'ERREUR',
  dashboard_online: 'EN LIGNE',
  dashboard_offline: 'HORS LIGNE',
  'dashboard organic': 'Biologique',
  'dashboard referal': 'Référence',
  dashboard_see_all: 'TOUT VOIR',
  /* END DASHBOARD */
  /* Sidebar */
  date_range: 'Plage de dates',
  sidebar_dashboard: 'Tableau de bord',
  sidebar_clients_overview: 'Gestion des clients',
  sidebar_clients: 'Clients',
  sidebar_service_clients: 'Service clients',
  sidebar_devices_overview: 'Gestion des appareils',
  sidebar_devices: 'Aperçu des appareils',
  sidebar_projects: 'Projets',
  sidebar_presets: 'Préréglages',
  sidebar_fortes_users: 'Administrateurs',
  sidebar_fortes_settings: 'Paramètres',
  sidebar_audit: 'Audit',
  sidebar_reports: 'Rapports',
  sidebar_total_devices: 'Appareils - Total',
  sidebar_online_devices: 'Appareils - En ligne',
  sidebar_offline_devices: 'Appareils - Hors ligne',
  sidebar_error_devices: 'Appareils - Alarme',
  sidebar_change_logs: 'Journaux de modifications',
  sidebar_version_title: 'Version',
  image_upload_success: "L'image a été mise à jour avec succès !",
  image_upload_file_type_error:
           'Seuls les fichiers JPEG et PNG sont autorisés.',
  image_upload_file_size_error:
           'La taille du fichier doit être inférieure à 5 Mo.',
  image_uploading_text: 'Chargement en cours...',
  /* END Sidebar */
  /* Navbar */
  navbar_search: 'Rechercher',
  navbar_notification: 'Notification',
  navbar_no_notification: "Il n'y a pas de notifications",
  navbar_profile: 'Profil',
  navbar_setting: 'Paramètres du compte',
  navbar_logout: 'Se déconnecter',
  /* END Navbar */
  active_label: 'ACTIF',
  deactive_label: 'INACTIF',
  please_fix_form_errors: 'Veuillez corriger les erreurs du formulaire',
  /* Clients */
  clients: 'Clients',
  clients_all: 'TOUS',
  clients_active: 'ACTIF',
  clients_inactive: 'INACTIF',
  clients_add_new: 'Ajouter un nouveau client',
  clients_filter_search: 'Rechercher un client',
  clients_sort_by_filter_label: 'Trier par',
  clients_sort_by_value_1: 'Date (plus récent)',
  clients_sort_by_value_2: 'Date (plus ancien)',
  clients_sort_by_value_3: 'Alphabétique',
  clients_country_filter_label: 'Pays',
  clients_filter_reset_button: 'Réinitialiser',
  clients_filter_delete_button: 'Désactiver',
  clients_table_name: 'Nom',
  clients_table_country: 'Pays',
  clients_table_devices: 'Appareils',
  clients_table_added: 'Date de création',
  clients_table_status: 'Statut',
  clients_table_row_per_page: 'Lignes par page',
  clients_bread_crumb_main_title: 'Clients',
  add_clients_bread_crumb_first_link: 'Ajouter un client',
  add_clients_form_header: 'Ajouter un client',
  edit_clients_bread_crumb_first_link: 'Modifier le client',
  edit_clients_form_header: 'Modifier le client',
  clients_form_client_name_field: 'Nom',
  clients_form_email_field: 'Adresse e-mail',
  clients_form_user_field: 'Utilisateur',
  clients_form_city_field: 'Ville',
  clients_form_country_field: 'Pays',
  clients_form_client_3_scale_role_field: 'Rôle 3Scale',
  clients_form_client_3_scale_role_member_label: 'Membre',
  clients_form_client_3_scale_role_admin_label: 'Admin',
  clients_form_allow_customization_field: 'Autoriser le marquage blanc',
  clients_form_add_client_button: 'Ajouter le client',
  clients_form_edit_client_button: 'Enregistrer les modifications',
  clients_form_cancel_button: 'Annuler',
  clients_form_submitting_client_button: 'Envoi en cours...',
  clients_name_required_form_error: 'Le nom est requis',
  clients_email_required_form_error: "L'adresse e-mail est requise",
  clients_api_3scale_role_required_form_error:
           'Le rôle 3Scale est requis',
  clients_email_valid_form_error: 'Doit être une adresse e-mail valide',
  clients_city_required_form_error: 'La ville est requise',
  clients_country_required_form_error: 'Le pays est requis',
  clients_add_form_success: 'Client créé avec succès !',
  clients_edit_form_success: 'Le client a été mis à jour avec succès !',
  clients_form_api_error: 'Alarme serveur interne du client - API',
  clients_form_email_error: "L'adresse e-mail existe déjà",
  clients_no_record_found: 'Aucun client trouvé',
  client_deactivated_message: 'Le client a été désactivé avec succès',
  client_activated_message: 'Le client a été activé avec succès',
  clients_bulk_no_user_is_selected_error_message:
           'Veuillez sélectionner plusieurs enregistrements à désactiver',
  clients_bulk_deactivated_message:
           '{{ USERS_COUNT }} clients ont été désactivés avec succès',
  client_single_stats_table_title: 'Statut',
  /* END Clients */
  client_single_first_bread_crumb: 'Clients',
  client_single_second_bread_crumb: 'Voir le client',
  client_single_country_label: 'Pays',
  client_single_devices_label: 'Appareils',
  client_single_edit_button: 'Modifier',
  client_single_users_tab: 'Utilisateurs',
  client_single_apis_tab: 'Clés API',
  client_single_customization_tab: 'Marque blanche',
  client_single_customization_tab_logo: 'Logo',
  client_single_customization_tab_primary_color: 'Couleur principale',
  client_single_customization_tab_secondary_color: 'Couleur secondaire',
  client_single_customization_tab_submit_button:
           'Enregistrer les modifications',
  client_single_customization_color_schema_success:
           'Marque blanche mise à jour avec succès.',
  client_single_filter_search: 'Rechercher',
  client_single_add_new_user_button: 'Ajouter un utilisateur',
  client_single_add_new_api_key_button: 'Ajouter une clé API',
  client_single_role_id_filter: 'Rôle',
  client_single_users_search: 'Rechercher',
  client_single_filter_reset_button: 'Réinitialiser',
  client_single_users_all: 'TOUS',
  client_single_users_active: 'ACTIF',
  client_single_users_inactive: 'INACTIF',
  client_single_filter_delete_button: 'Désactiver',
  client_single_users_table_name: 'Nom',
  client_single_users_table_address: 'Adresse',
  client_single_users_table_role_id: 'Rôle',
  client_single_users_table_status: 'Statut',
  client_single_users_table_added: 'Date de création',
  client_single_users_table_no_record_found:
           'Aucun enregistrement trouvé',
  client_single_table_row_per_page: 'Lignes par page',
  client_single_stats_online_devices_label: 'Appareils en ligne',
  client_single_stats_error_devices_label: 'Appareils en alarme',
  client_single_no_user_selected_for_de_activation_message:
           'Veuillez sélectionner un enregistrement à désactiver',
  /* END Clients Single */
  /* Service Clients */
  service_clients: 'Service Clients',
  service_clients_all: 'TOUS',
  service_clients_active: 'ACTIF',
  service_clients_inactive: 'INACTIF',
  service_clients_add_new: 'Ajouter un nouveau',
  service_clients_filter_search: 'Rechercher',
  service_clients_sort_by_filter_label: 'Trier par',
  service_clients_sort_by_value_1: 'Date (plus récent)',
  service_clients_sort_by_value_2: 'Date (plus ancien)',
  service_clients_sort_by_value_3: 'Alphabétique',
  service_clients_country_filter_label: 'Pays',
  service_clients_filter_reset_button: 'Réinitialiser',
  service_clients_filter_delete_button: 'Désactiver',
  service_clients_table_name: 'Nom',
  service_clients_table_country: 'Pays',
  service_clients_table_devices: 'Appareils',
  service_clients_table_added: 'Date de création',
  service_clients_table_status: 'Statut',
  service_clients_table_row_per_page: 'Lignes par page',
  service_clients_bread_crumb_main_title: 'Service Clients',
  add_service_clients_bread_crumb_first_link:
           'Ajouter un service client',
  add_service_clients_form_header: 'Ajouter un service client',
  edit_service_clients_bread_crumb_first_link:
           'Modifier le service client',
  edit_service_clients_form_header: 'Modifier le service client',
  service_clients_form_service_client_name_field: 'Nom',
  service_clients_form_email_field: 'Adresse e-mail',
  service_clients_form_client_field: 'Client',
  service_clients_form_user_field: 'Utilisateur',
  service_clients_form_city_field: 'Ville',
  service_clients_form_country_field: 'Pays',
  service_clients_form_add_service_client_button:
           'Enregistrer les modifications',
  service_clients_form_edit_service_client_button:
           'Enregistrer les modifications',
  service_clients_form_cancel_button: 'Annuler',
  service_clients_form_submitting_service_client_button:
           'Envoi en cours...',
  service_clients_name_required_form_error: 'Le nom est requis',
  service_clients_email_required_form_error:
           "L'adresse e-mail est requise",
  service_clients_focal_user_id_required_form_error:
           "L'utilisateur focal est requis",
  service_clients_email_valid_form_error:
           'Doit être une adresse e-mail valide',
  service_clients_city_required_form_error: 'La ville est requise',
  service_clients_client_required_form_error: 'Le client est requis',
  service_clients_country_required_form_error: 'Le pays est requis',
  service_clients_add_form_success: 'Service client créé avec succès !',
  service_clients_edit_form_success:
           'Le service client a été mis à jour avec succès !',
  service_clients_form_api_error:
           'Alarme serveur interne du service client - API',
  service_clients_form_email_error: "L'adresse e-mail existe déjà",
  service_clients_no_record_found: 'Aucun service client trouvé',
  service_client_deactivated_message:
           'Le service client a été désactivé avec succès',
  service_client_activated_message:
           'Le service client a été activé avec succès',
  service_clients_bulk_no_user_is_selected_error_message:
           'Veuillez sélectionner des enregistrements à désactiver',
  service_clients_bulk_deactivated_message:
           '{{ USERS_COUNT }} service(s) client(s) ont été désactivés avec succès',
  service_client_single_stats_table_title: 'Statut',
  /* END Service Clients */
  service_client_single_first_bread_crumb: 'Service Clients',
  service_client_single_second_bread_crumb: 'Voir le service client',
  service_client_single_country_label: 'Pays',
  service_client_single_devices_label: 'Appareils',
  service_client_single_edit_button: 'Modifier',
  service_client_single_users_tab: 'Utilisateurs',
  service_client_single_apis_tab: 'Clés API',
  service_client_single_filter_search: 'Rechercher',
  service_client_single_add_new_user_button: 'Ajouter un utilisateur',
  service_client_single_add_new_api_key_button: 'Ajouter une clé API',
  service_client_single_role_id_filter: 'Rôle',
  service_client_single_users_search: 'Rechercher',
  service_client_single_filter_reset_button: 'Réinitialiser',
  service_client_single_users_all: 'TOUS',
  service_client_single_users_active: 'ACTIF',
  service_client_single_users_inactive: 'INACTIF',
  service_client_single_filter_delete_button: 'Désactiver',
  service_client_single_users_table_name: 'Nom',
  service_client_single_users_table_address: 'Adresse',
  service_client_single_users_table_role_id: 'Rôle',
  service_client_single_users_table_status: 'Statut',
  service_client_single_users_table_added: 'Date de création',
  service_client_single_users_table_no_record_found:
           'Aucun enregistrement trouvé',
  service_client_single_table_row_per_page: 'Lignes par page',
  service_client_single_stats_active_devices_label: 'Appareils en ligne',
  service_client_single_stats_error_devices_label: 'Appareils en alarme',
  service_client_single_no_user_selected_for_de_activation_message:
           'Veuillez sélectionner des enregistrements à désactiver',
  /* END Service Clients Single */
  /* Users */
  users_bread_crumb_main_title: 'Client',
  add_users_bread_crumb_first_link: 'Ajouter un utilisateur',
  add_users_form_header: 'Ajouter un utilisateur',
  edit_users_bread_crumb_first_link: "Modifier l'utilisateur",
  edit_users_form_header: "Modifier l'utilisateur",
  users_form_first_name_field: 'Prénom',
  users_form_last_name_field: 'Nom de famille',
  users_form_email_field: 'Adresse e-mail',
  users_form_official_email_field: 'Adresse e-mail officielle',
  users_form_phone_field: 'Numéro de téléphone',
  users_form_official_phone_number_field: 'Numéro de téléphone officiel',
  users_form_password_field: 'Mot de passe',
  users_form_role_id_field: 'Rôle',
  users_form_select_role_id_field: 'Sélectionner un rôle',
  users_form_street_number_field: 'Rue',
  users_form_house_number_field: 'Numéro de maison',
  users_form_neighbourhood_field: 'Quartier',
  users_form_postal_code_field: 'Code postal',
  users_form_city_field: 'Ville',
  users_form_country_field: 'Pays',
  users_form_email_verification_field:
           "Vérification de l'adresse e-mail",
  users_form_email_verification_sub_title_field:
           "La désactivation de ce bouton enverra automatiquement à l'utilisateur un e-mail de vérification",
  users_form_add_user_button: 'Enregistrer les modifications',
  users_form_edit_user_button: 'Enregistrer les modifications',
  users_form_cancel_button: 'Annuler',
  users_form_submitting_user_button: 'Envoi en cours...',
  users_first_name_required_form_error: 'Le prénom est requis',
  users_last_name_required_form_error: 'Le nom de famille est requis',
  users_email_required_form_error: "L'adresse e-mail est requise",
  users_email_valid_form_error: 'Doit être une adresse e-mail valide',
  users_password_required_form_error: 'Le mot de passe est requis',
  users_country_required_form_error: 'Le pays est requis',
  users_role_id_required_form_error: 'Le rôle est requis',
  users_password_form_error:
           'Le mot de passe doit contenir au moins 8 caractères, dont au moins une lettre majuscule, au moins un chiffre et au moins un caractère spécial.',
  users_add_form_success: 'Utilisateur créé avec succès !',
  users_edit_form_success:
           "L'utilisateur a été mis à jour avec succès !",
  users_form_api_error: 'Alarme serveur interne des utilisateurs - API',
  users_form_email_error: "L'adresse e-mail existe déjà",
  users_no_record_found: 'Aucun utilisateur trouvé',
  user_deactivated_message: "L'utilisateur a été désactivé avec succès",
  user_activated_message: "L'utilisateur a été activé avec succès",
  user_email_verified_title: 'Adresse e-mail vérifiée',
  user_email_verified_sub_title:
           "La désactivation de ce bouton enverra automatiquement à l'utilisateur un e-mail de vérification",
  users_bulk_no_user_is_selected_error_message:
           'Veuillez sélectionner des enregistrements à désactiver',
  users_bulk_deactivated_message:
           '{{ USERS_COUNT }} utilisateurs ont été désactivés avec succès',
  /** Projects */
  projects: 'Projets',
  projects_all: 'TOUS',
  projects_active: 'ACTIF',
  projects_inactive: 'INACTIF',
  projects_unassigned: 'NON ATTRIBUÉ',
  projects_add_new: 'Ajouter un nouveau',
  projects_filter_search: 'Rechercher un projet',
  projects_sort_by_filter_label: 'Trier par',
  projects_sort_by_value_1: 'Date (plus récent)',
  projects_sort_by_value_2: 'Date (plus ancien)',
  projects_sort_by_value_3: 'Alphabétique',
  projects_country_filter_label: 'Pays',
  projects_filter_reset_button: 'Réinitialiser',
  projects_filter_delete_button: 'Supprimer',
  projects_table_name: 'Nom',
  projects_table_batch_number: 'Numéro de lot',
  projects_table_client: 'Clients',
  projects_table_devices: 'Appareils',
  projects_table_added: 'Date de création',
  projects_table_status: 'Statut',
  projects_table_row_per_page: 'Lignes par page',
  projects_bread_crumb_main_title: 'Projets',
  add_projects_bread_crumb_first_link: 'Ajouter un projet',
  add_projects_form_header: 'Ajouter un projet',
  edit_projects_bread_crumb_first_link: 'Modifier le projet',
  edit_projects_form_header: 'Modifier le projet',
  projects_form_project_name_field: 'Nom du projet',
  projects_form_email_field: 'Adresse e-mail',
  projects_form_client_field: 'Client',
  projects_form_service_client_field: 'Service client',
  projects_form_user_field: 'Utilisateur',
  projects_form_description_field: 'Description (facultatif)',
  projects_form_add_project_button: 'Enregistrer les modifications',
  projects_form_edit_project_button: 'Enregistrer les modifications',
  projects_form_cancel_button: 'Annuler',
  projects_form_submitting_service_client_button: 'Envoi en cours...',
  projects_name_required_form_error: 'Le nom est requis',
  projects_form_unqiue_project_order_number_error:
           'Le numéro de commande du projet doit être unique',
  projects_batch_number_required_form_error:
           'Le numéro de lot est requis',
  projects_client_required_form_error: 'Le client est requis',
  projects_description_required_form_error: 'La description est requise',
  projects_add_form_success: 'Projet créé avec succès !',
  projects_edit_form_success: 'Le projet a été mis à jour avec succès !',
  projects_form_api_error: 'Alarme serveur interne des projets - API',
  projects_no_record_found: 'Aucun projet trouvé',
  projects_deactivated_message: 'Le projet a été désactivé avec succès',
  projects_activated_message: 'Le projet a été activé avec succès',
  projects_bulk_no_user_is_selected_error_message:
           'Veuillez sélectionner des enregistrements à désactiver',
  projects_bulk_deactivated_message:
           '{{ USERS_COUNT }} projets ont été désactivés avec succès',
  projects_single_stats_table_title: 'Statut',
  /** Devices */
  devices: 'Appareils',
  devices_all: 'Tous',
  devices_active: 'En ligne',
  devices_inactive: 'Hors ligne',
  devices_error: 'ERREUR',
  devices_register_enabled: 'Activé',
  devices_register_disabled: 'Désactivé',
  devices_add_new: 'Ajouter un nouveau',
  devices_name: 'Nom',
  devices_address: "Adresse d'installation",
  devices_client: 'Client',
  devices_project: 'Projet',
  devices_status: 'Statut',
  devices_description: "Module Thermique d'Appartement",
  device_is_online: 'L\'appareil est "en ligne"',
  device_is_offline: 'L\'appareil est "hors ligne"',
  devices_last_viewed: 'Dernière vue',
  devices_action: 'Action',
  devices_search: 'Recherche',
  devices_row_per_page: 'Lignes par page',
  device_rssi_title: 'RSSI',
  device_status_reg_105_no_error_255: "Pas d'erreur",
  device_status_menu_list: JSON.stringify([
    {
      key: 'operating_state',
      value: 'État de fonctionnement',
      permission: 'operating-state-tab-read',
    },
    {
      key: 'settings_hot_water',
      value: 'Réglages eau chaude',
      permission: 'settings-hot-water-tab-read',
    },
    {
      key: 'settings_heating_and_cooling',
      value: 'Réglages chauffage et refroidissement',
      permission: 'settings-heating-and-cooling-tab-read',
    },
    {
      key: 'manual_control',
      value: 'Contrôle manuel',
      permission: 'manual-control-tab-read',
    },
    {
      key: 'm_bus_meters',
      value: 'Compteurs M-Bus',
      permission: 'm-bus-meters-tab-read',
    },
    {
      key: 'live_hot_water',
      value: 'Journal en direct eau chaude',
      permission: 'livelog-hot-water-tab-read',
    },
    {
      key: 'live_heating',
      value: 'Journal en direct chauffage',
      permission: 'livelog-heating-tab-read',
    },
    {
      key: 'live_cooling',
      value: 'Journal en direct refroidissement',
      permission: 'livelog-cooling-tab-read',
    },
  ]),
  device_status_menu_keys_list: JSON.stringify([
    'operating_state',
    'settings_hot_water',
    'settings_heating_and_cooling',
    'manual_control',
    'm_bus_meters',
    'live_hot_water',
    'live_heating',
    'live_cooling',
  ]),
  devices_filter_search: 'Rechercher',
  devices_sort_by_filter_label: 'Trier par',
  devices_sort_by_value_1: 'Date (plus récent)',
  devices_sort_by_value_2: 'Date (plus ancien)',
  devices_sort_by_value_3: 'Alphabétique',
  devices_country_filter_label: 'Pays',
  devices_filter_reset_button: 'Réinitialiser',
  devices_filter_delete_button: 'Supprimer',
  devices_table_name: 'Nom',
  devices_table_firmware: 'Firmware',
  devices_table_device_type: "Type d'appareil",
  devices_table_batch_number: 'Numéro de lot',
  devices_table_client: 'Clients',
  devices_table_devices: 'Appareils',
  devices_table_added: 'Date de création',
  devices_table_status: 'Statut',
  devices_table_row_per_page: 'Lignes par page',
  devices_bread_crumb_main_title: 'Appareils',
  add_devices_bread_crumb_first_link: 'Ajouter un appareil',
  add_devices_form_header: 'Ajouter un appareil',
  edit_devices_bread_crumb_first_link: "Modifier l'appareil",
  edit_devices_form_header: "Modifier l'appareil",
  devices_form_device_information_tab: "Informations sur l'appareil",
  devices_form_name_field: "Nom de l'appareil",
  devices_form_type_field: "Type d'appareil",
  devices_form_ip_address_field: 'Adresse IP',
  devices_form_preset_field: 'Préréglage',
  devices_form_project_field: 'Projet',
  devices_form_client_field: 'Client',
  devices_form_batch_number_field: 'Numéro de lot',
  devices_form_serial_number_field: 'Numéro de série Mercurius',
  devices_form_box_serial_number_field:
           "Numéro de série Unité d'interface thermique",
  devices_form_firmware_field: 'Version du firmware',
  devices_form_description_field: "Module Thermique d'Appartement",
  devices_form_device_address_tab: "Adresse d'installation",
  devices_form_street_number_field: 'Rue',
  devices_form_house_number_field: 'Numéro de maison',
  devices_form_postal_code_field: 'Code postal',
  devices_form_neighbourhood_field: 'Informations supplémentaires',
  devices_form_city_field: 'Ville',
  devices_form_country_field: 'Pays',
  devices_form_floor_number_field: 'Étage',
  devices_form_building_number_field: 'Numéro de construction',
  devices_form_additional_information_tab:
           'Informations supplémentaires',
  devices_form_sn_heat_unit_field:
           'Numéro de série unité d’interface thermique',
  devices_form_article_number_field:
           'Numéro d’article unité d’interface thermique',
  devices_form_prod_order_nr_week_field:
           'Numéro de commande de production',
  devices_form_serial_number_mercurius_field:
           'Numéro de série Mercurius',
  devices_form_sim_card_number_field: 'Numéro de carte SIM',
  devices_form_customer_ref_field: 'Référence du client',
  devices_form_production_date_field: 'Date de production',
  devices_form_order_number_field: 'Numéro de commande',
  devices_form_product_number_field: 'Numéro d’article',
  devices_form_model_number_field: 'Numéro de modèle',
  devices_form_note_field: 'Note (facultatif)',
  devices_form_settings_tab: 'Réglages',
  devices_form_device_interval_field: 'Intervalle',
  devices_form_is_device_enabled_sensor_field:
           'Activer les données du capteur',
  devices_form_is_device_activated_field: "Désactiver l'appareil",
  devices_form_mbus_tab: 'M-Bus',
  devices_form_is_device_enabled_heat_meter_field:
           'Activer le compteur de chaleur',
  devices_form_is_device_enabled_cool_meter_field:
           'Activer le compteur de froid',
  devices_form_is_device_enabled_electric_meter_field:
           "Activer le compteur d'électricité",
  devices_form_is_device_enabled_bi_directional_meter_field:
           'Activer le compteur de chaleur/froid',
  devices_form_is_device_enabled_water_meter_field:
           "Activer le compteur d'eau",
  devices_form_is_device_enabled_mbus_search_meter_button:
           'Recherche M-Bus',
  devices_form_add_device_button: 'Ajouter un appareil',
  devices_form_edit_device_button: 'Enregistrer les modifications',
  devices_form_cancel_button: 'Annuler',
  devices_form_submitting_service_client_button:
           'En cours de soumission...',
  devices_name_required_form_error: 'Le nom est requis',
  devices_batch_number_required_form_error:
           'Le numéro de lot est requis',
  devices_serial_number_required_form_error:
           'Le numéro de série est requis',
  devices_sn_heat_number_numeric_form_error:
           "Le numéro de série de l'unité d'interface thermique doit être un nombre",
  devices_article_number_form_error: 'Le numéro d’article est requis',
  devices_prod_order_nr_week_form_error:
           'Le numéro de commande de production est requis',
  devices_serial_number_mercurius_form_error:
           'Le numéro de série Mercurius est requis',
  devices_sim_card_number_form_error:
           'Le numéro de carte SIM est requis',
  devices_device_interval_numeric_form_error:
           "L'intervalle de l'appareil doit être un nombre",
  devices_client_required_form_error: 'Le client est requis',
  devices_project_required_form_error: 'Le projet est requis',
  devices_preset_required_form_error: 'Le préréglage est requis',
  devices_description_required_form_error: 'La description est requise',
  devices_add_form_success: 'Appareil créé avec succès !',
  devices_edit_form_success: "L'appareil a été mis à jour avec succès !",
  devices_form_unique_error:
           "Le numéro de série de l'unité d'interface thermique et le numéro de série Mercurius doivent être uniques",
  devices_form_api_error: 'Alarme interne - API des appareils',
  devices_no_record_found: 'Aucun appareil trouvé',
  devices_deactivated_message: "L'appareil a été désactivé avec succès",
  devices_activated_message: "L'appareil a été activé avec succès",
  devices_search_mbus_registers_success:
           "Le message a été envoyé avec succès à l'appareil.",
  devices_search_mbus_registers_missing_serial_number_error:
           'Veuillez remplir le numéro de série pour continuer.',
  devices_bulk_no_user_is_selected_error_message:
           'Veuillez sélectionner des enregistrements à désactiver',
  devices_bulk_deactivated_message:
           '{{ USERS_COUNT }} appareils ont été désactivés avec succès',
  // Devices Single
  device_single_page_title: "Détails de l'appareil",
  devices_single_bread_crumb_main_title: "Vue d'ensemble de l'appareil",
  devices_single_bread_crumb_first_link: "Page de l'appareil",
  devices_single_basic_details_client: 'CLIENT',
  devices_single_basic_details_serial_number:
           'NUMÉRO DE SÉRIE MERCURIUS',
  devices_single_basic_details_firmware: 'FIRMWARE',
  devices_single_basic_details_type: "TYPE D'APPAREIL",
  devices_single_edit_device_button: "Modifier l'appareil",
  device_single_error_status: 'Erreur',
  device_single_online_status: 'En ligne',
  device_single_offline_status: 'Hors ligne',
  device_single_rss_device_status: "Statut de l'appareil",
  device_single_rss_signal_strength: 'Force du signal',
  device_single_temperature_tab: 'Graphique de température',
  device_single_temperature_title:
           "Historique de l'unité d'interface thermique",
  device_single_temperature_hr: 'Heure',
  device_single_registers_tab: 'Registres',
  device_single_event_tab: 'Événements',
  device_single_device_details_tab: 'Infos',
  device_single_device_api_timer_seconds_label: 'Secondes',
  device_single_device_api_timer_minute_label: 'Minute',
  device_single_device_api_timer_minutes_label: 'Minutes',
  device_single_recall_device_status_api_timer_field: 'Intervalle API',
  devices_single_last_seen: 'Dernière connexion',
  devices_single_fetch_registers: 'COLLECTE DE DONNÉES',
  fetch_registers_success:
           "La demande a été envoyée avec succès à l'appareil.",
  write_registers_success:
           "Le message d'écriture a été envoyé avec succès à l'appareil.",
  write_registers_error: 'Veuillez changer la valeur pour continuer.',
  fetch_registers_m_bus_meters_not_supported:
           'Les compteurs M-Bus ne sont pas pris en charge pour le moment.',
  // Operating Status
  business_situation_operating_status_title:
           "État de fonctionnement de l'unité d'interface thermique",
  device_status_nr: 'NR',
  device_status_name: 'Nom',
  device_status_value: 'Valeur',
  business_situation_operating_status_100: 'État de chauffage',
  business_situation_operating_status_100_list: JSON.stringify({
    list: {
      0: 'Inactif',
      1: 'Demande',
      2: 'Demande',
      3: 'Circulation après',
      4: 'Désactivé',
      5: 'Circulation avant',
    },
  }),
  business_situation_operating_status_101: "État de l'eau chaude",
  business_situation_operating_status_101_list: JSON.stringify({
    list: {
      0: 'Inactif',
      1: 'Demande',
      2: 'Demande',
      3: 'Circulation après',
      4: 'Désactivé',
    },
  }),
  business_situation_operating_status_102: 'État de préchauffage',
  business_situation_operating_status_102_list: JSON.stringify({
    list: {
      0: 'Inactif',
      1: 'Demande',
    },
  }),
  business_situation_operating_status_103:
           'État de désinfection thermique',
  business_situation_operating_status_103_list: JSON.stringify({
    list: {
      0: 'Inactif',
      1: 'Demande',
      2: 'Demande',
      3: 'Circulation après',
      4: 'Désactivé',
    },
  }),
  business_situation_operating_status_104: 'État de refroidissement',
  business_situation_operating_status_104_list: JSON.stringify({
    list: {
      0: 'Inactif',
      1: 'Demande',
      2: 'Initialisation',
      3: 'Demande',
      4: 'Circulation après',
      5: 'Circulation avant',
    },
  }),
  business_situation_operating_status_1802:
           "État de l'élément chauffant TE",
  business_situation_operating_status_1802_list: JSON.stringify({
    list: {
      0: 'Désactivé',
      1: 'Activé',
    },
  }),
  business_situation_operating_status_105: "Code d'alarme",
  business_situation_operating_status_106: 'Version du logiciel',
  business_situation_operating_status_107: "Vanne d'eau chaude (M1)",
  business_situation_operating_status_108:
           'Vanne de chauffage/refroidissement (M2)',
  business_situation_operating_status_109: 'Pompe',
  business_situation_operating_status_109_list: JSON.stringify({
    list: {
      0: 'Désactivée',
      1: 'Activée',
    },
  }),
  business_situation_operating_status_110: 'Vanne 6 voies',
  business_situation_operating_status_110_list: JSON.stringify({
    list: {
      0: 'Position : Chauffage',
      1: 'Position : Refroidissement',
    },
  }),
  business_situation_operating_status_112_list: JSON.stringify({
    list: {
      0: 'Chauffage ou demande',
      1: 'Refroidissement',
    },
  }),
  business_situation_operating_status_113:
           "Entrée du thermostat d'ambiance",
  business_situation_operating_status_113_list: JSON.stringify({
    list: {
      1: 'Pas de demande de chauffage',
      2: 'Demande de chauffage',
    },
  }),
  business_situation_operating_status_114: 'Indicateur OpenTherm',
  business_situation_operating_status_114_list: JSON.stringify({
    list: {
      0: 'Connexion inactive',
      1: 'Connexion active',
    },
  }),
  business_situation_operating_status_115: "Type d'appareil",
  // Business situation
  business_situation_measured_values_title: 'Mesures',
  business_situation_measured_values_200: "Débit d'eau chaude",
  business_situation_measured_values_201: "Température de l'eau chaude",
  business_situation_measured_values_202: 'Approvisionnement primaire',
  business_situation_measured_values_203: 'Retour primaire/Extérieur',
  business_situation_measured_values_205: 'Approvisionnement secondaire',
  business_situation_measured_values_206: 'Retour secondaire',
  business_situation_measured_values_204:
           'Point de consigne de chauffage calculé',
  business_situation_measured_values_207:
           'Température ambiante (OpenTherm)',
  business_situation_measured_values_208:
           'Point de consigne de la pièce (OpenTherm)',
  business_situation_measured_values_210: "Pression d'eau secondaire",
  // Operating time
  business_situation_operating_time_title: 'Temps de fonctionnement',
  business_situation_operating_time_800:
           'Temps de fonctionnement total du système',
  business_situation_operating_time_801:
           'Temps de combustion de chauffage',
  business_situation_operating_time_802:
           'Temps de combustion de refroidissement',
  business_situation_operating_time_803:
           "Temps de combustion de l'eau chaude",
  business_situation_operating_time_804: 'Nombre de puisages',
  business_situation_operating_time_805: "Volume d'eau chaude",
  business_situation_operating_time_806: 'Coupures de courant',
  // Warmtapwater
  settings_dhw_warm_tap_water_title: 'Réglages eau chaude sanitaire',
  settings_dhw_warm_tap_water_300: "Point de consigne de l'eau chaude",
  settings_dhw_warm_tap_water_301: 'Mode préchauffage',
  settings_dhw_warm_tap_water_301_list: JSON.stringify({
    list: {
      0: 'Désactivé',
      1: 'Antigel',
      2: 'Économie',
      3: 'Confort',
      4: 'Dynamique (recommandé)',
    },
  }),
  settings_dhw_warm_tap_water_302: 'Point de consigne de confort',
  settings_dhw_warm_tap_water_303: 'Seuil de débit minimal',
  settings_dhw_warm_tap_water_304: "Activation de l'eau chaude",
  settings_dhw_warm_tap_water_304_list: JSON.stringify({
    list: {
      0: 'Désactivé',
      1: 'Activé',
    },
  }),
  // Thermal disinfection
  settings_thermal_disinfection_title: 'Réglages Désinfection thermique',
  settings_thermal_disinfection_400: 'Désinfection thermique activée',
  settings_thermal_disinfection_400_list: JSON.stringify({
    list: {
      0: 'Désactivé',
      1: 'Activé',
    },
  }),
  settings_thermal_disinfection_401:
           'Point de consigne de désinfection thermique',
  settings_thermal_disinfection_402:
           'Durée de combustion de la désinfection thermique',
  settings_thermal_disinfection_403:
           "Temps d'attente de la désinfection thermique",
  // TE-Booster
  settings_dhw_te_booster_title: 'Réglages TE-Booster',
  settings_dhw_te_booster_1800: 'TE-Booster',
  settings_dhw_te_booster_1800_list: JSON.stringify({
    list: {
      0: 'Désactivé',
      1: 'Activé',
    },
  }),
  settings_dhw_te_booster_1804: 'Point de consigne TE-Booster',
  settings_dhw_te_booster_1805:
           'Hystérésis du point de consigne TE-Booster',
  settings_dhw_te_booster_1812: 'Délai du point de consigne TE-Booster',
  settings_dhw_te_booster_1813:
           'Minuteur du point de consigne TE-Booster',
  settings_dhw_te_booster_1815: 'Seuil TE-Booster',
  settings_dhw_te_booster_1816: 'Volume enregistré TE-Booster',
  // Heating
  heating_and_cooling_settings_heating_title: 'Réglages chauffage',
  heating_and_cooling_settings_heating_500:
           'Point de consigne maximal chauffage',
  heating_and_cooling_settings_heating_501:
           'Point de consigne minimal chauffage',
  heating_and_cooling_settings_heating_506: 'Retour primaire maximal',
  heating_and_cooling_settings_heating_507:
           'Facteur de compensation de retour primaire',
  heating_and_cooling_settings_heating_508:
           'Différence de consigne primaire-secondaire',
  heating_and_cooling_settings_heating_510:
           'Température maximale de chauffage',
  heating_and_cooling_settings_heating_509:
           'Différence minimale primaire-secondaire',
  heating_and_cooling_settings_heating_514:
           'Point de consigne de chauffage',
  heating_and_cooling_settings_heating_515: 'Activation du chauffage',
  heating_and_cooling_settings_heating_515_list: JSON.stringify({
    list: {
      0: 'Désactivé',
      1: 'Activé',
    },
  }),
  // Cooling
  heating_and_cooling_settings_cooling_title: 'Réglages refroidissement',
  heating_and_cooling_settings_cooling_600:
           'Point de consigne de refroidissement',
  heating_and_cooling_settings_cooling_601: 'Retour primaire minimal',
  heating_and_cooling_settings_cooling_602:
           'Facteur de compensation de retour primaire',
  heating_and_cooling_settings_cooling_605:
           'Température maximale de refroidissement',
  heating_and_cooling_settings_cooling_606:
           'Point de consigne de refroidissement minimal',
  heating_and_cooling_settings_cooling_607:
           'Point de consigne de refroidissement maximal',
  heating_and_cooling_settings_cooling_611:
           'Activation du refroidissement',
  heating_and_cooling_settings_cooling_611_list: JSON.stringify({
    list: {
      0: 'Désactivé',
      1: 'Activé',
    },
  }),
  // Secondary General
  heating_and_cooling_settings_secondary_general_title:
           'Réglages généraux',
  heating_and_cooling_settings_secondary_general_512:
           'Pression minimale',
  heating_and_cooling_settings_secondary_general_513:
           'Pression maximale',
  heating_and_cooling_settings_secondary_general_603:
           "Temps d'attente pour basculer chauffage et refroidissement",
  heating_and_cooling_settings_secondary_general_604:
           "Temps d'attente de la vanne 6 voies",
  // Changeover WKW
  heating_and_cooling_settings_changeover_wkw_title:
           'Réglages systèmes 3,5 tubes',
  heating_and_cooling_settings_changeover_wkw_1850:
           'Configuration 3,5 tubes',
  heating_and_cooling_settings_changeover_wkw_1850_list: JSON.stringify({
    list: {
      0: 'Chauffage',
      1: 'Refroidissement',
    },
  }),
  heating_and_cooling_settings_changeover_wkw_1851:
           'Seuil de détection du refroidissement',
  heating_and_cooling_settings_changeover_wkw_1852:
           'Seuil de détection du chauffage',
  heating_and_cooling_settings_changeover_wkw_1853:
           'Point de consigne de chauffage abaissé',
  heating_and_cooling_settings_changeover_wkw_1854:
           'Point de consigne abaissé utilisé',
  heating_and_cooling_settings_changeover_wkw_1854_list: JSON.stringify({
    list: {
      0: 'Désactivé',
      1: 'Activé',
    },
  }),
  heating_and_cooling_settings_changeover_wkw_1855:
           'Position de pré-détection M2',
  heating_and_cooling_settings_changeover_wkw_1856:
           'Intervalle de détection',
  heating_and_cooling_settings_changeover_wkw_1857:
           "Minuteur de l'intervalle de détection",
  heating_and_cooling_settings_changeover_wkw_1858:
           'Délai de pré-détection',
  heating_and_cooling_settings_changeover_wkw_1859:
           'Minuteur de pré-détection',
  heating_and_cooling_settings_changeover_wkw_1860:
           'Minuteur de bascule de configuration',
  heating_and_cooling_settings_changeover_wkw_1861: 'Délai de bascule',
  heating_and_cooling_settings_changeover_wkw_1862:
           'Seuil de bascule de configuration',
  heating_and_cooling_settings_changeover_wkw_1863:
           'Mode de fonction Boost',
  heating_and_cooling_settings_changeover_wkw_1863_list: JSON.stringify({
    list: {
      0: 'Désactivé',
      1: 'Activé',
    },
  }),
  heating_and_cooling_settings_changeover_wkw_1864:
           'Minuteur de détection Boost',
  heating_and_cooling_settings_changeover_wkw_1865:
           'Délai de détection Boost',
  heating_and_cooling_settings_changeover_wkw_1866:
           'Position de boost maximale',
  // Manual Operation
  settings_manual_operation_title: 'Contrôle manuel',
  settings_manual_operation_700: 'Activation du contrôle manuel',
  settings_manual_operation_700_list: JSON.stringify({
    list: {
      0: 'Désactivé',
      1: 'Activé',
    },
  }),
  settings_manual_operation_701: 'Vanve eau chaude (M1)',
  settings_manual_operation_702: 'Vanve chauffage/refroidissement (M2)',
  settings_manual_operation_703: 'Pompe',
  settings_manual_operation_703_list: JSON.stringify({
    list: {
      0: 'Désactivé',
      1: 'Activé',
    },
  }),
  settings_manual_operation_704: 'Vanne 6 voies',
  settings_manual_operation_704_list: JSON.stringify({
    list: {
      0: 'Chauffage',
      1: 'Refroidissement',
    },
  }),
  settings_manual_operation_706: 'État de la demande de chaleur',
  settings_manual_operation_706_list: JSON.stringify({
    list: {
      0: 'Chauffage ou demande',
      1: 'Refroidissement',
    },
  }),
  settings_manual_operation_707: 'Vanve eau chaude (M1) [%]',
  settings_manual_operation_708:
           'Vanve chauffage/refroidissement (M2) [%]',
  settings_manual_operation_1801: 'Élément électrique TE-Booster',
  settings_manual_operation_1801_list: JSON.stringify({
    list: {
      0: 'Désactivé',
      1: 'Activé',
    },
  }),
  settings_manual_operation_709: 'Manual control timeout timer',
  // Live Tap Water
  live_tap_water_title: 'Eau Chaude en Temps Réel',
  live_tap_water_101: "État de l'eau chaude",
  live_tap_water_107: 'Vanve eau chaude (M1)',
  live_tap_water_200: 'Débit',
  live_tap_water_201: "Température de l'eau chaude",
  live_tap_water_202: 'Approvisionnement primaire',
  live_tap_water_203: 'Retour primaire',
  live_tap_water_1007: 'Débit primaire compteur M-Bus',
  live_tap_water_1008: 'Puissance',
  // Live CV
  live_cv_title: 'Chauffage en Temps Réel',
  live_cv_100: 'État de chauffage',
  live_cv_108: 'Vanve chauffage (M2)',
  live_cv_202: 'Approvisionnement primaire',
  live_cv_203: 'Retour primaire',
  live_cv_204: 'Point de consigne de chauffage calculé',
  live_cv_205: 'Approvisionnement secondaire',
  live_cv_206: 'Retour secondaire',
  live_cv_113: "Entrée thermostat d'ambiance",
  live_cv_210: "Pression d'eau secondaire",
  live_cv_1007: 'Débit primaire compteur M-Bus',
  live_cv_1008: 'Puissance',
  // Live Cooling
  live_cooling_title: 'Refroidissement en Temps Réel',
  live_cooling_104: 'État de refroidissement',
  live_cooling_109: 'Pompe',
  live_cooling_110: 'Vanne 6 voies',
  live_cooling_113: "Entrée thermostat d'ambiance",
  live_cooling_108: 'Vanne de refroidissement (M2)',
  live_cooling_202: 'Approvisionnement primaire',
  live_cooling_204: 'Point de consigne de chauffage calculé',
  live_cooling_205: 'Approvisionnement secondaire',
  live_cooling_206: 'Retour secondaire',
  // M Bus Limit DELETE ALL
  m_bus_limit_title: 'Limitation du Bus M',
  m_bus_limit_1500: 'Activation de la limitation',
  m_bus_limit_1501: 'Numéro de série du compteur limité',
  m_bus_limit_1503: 'État de limitation',
  m_bus_limit_1504: 'Configuration de limitation EC (h) et ECS (b)',
  m_bus_limit_1505: 'Limitation de puissance',
  m_bus_limit_1506: 'Limitation de débit',
  m_bus_limit_1507:
           'Limitation de température de retour (Uniquement pour ECS)',
  // Meter 1
  m_bus_meter_1_title: 'Compteur 1 (Chauffage)',
  m_bus_meter_1_0000: 'Numéro de série',
  m_bus_meter_1_0002: 'Fabricant',
  m_bus_meter_1_0003: 'Type de compteur',
  m_bus_meter_1_0004: 'État',
  m_bus_meter_1_0005: 'Débit',
  m_bus_meter_1_0006: 'Puissance',
  m_bus_meter_1_0007: "Température d'alimentation",
  m_bus_meter_1_0008: 'Température de retour',
  m_bus_meter_1_0010: 'Énergie de chauffage accumulée',
  m_bus_meter_1_0012: 'Volume accumulé',
  // Meter 2
  m_bus_meter_2_title: 'Compteur 2 (Refroidissement)',
  m_bus_meter_2_0100: 'Numéro de série',
  m_bus_meter_2_0102: 'Fabricant',
  m_bus_meter_2_0103: 'Type de compteur',
  m_bus_meter_2_0104: 'État',
  m_bus_meter_2_0105: 'Débit',
  m_bus_meter_2_0106: 'Puissance',
  m_bus_meter_2_0107: "Température d'alimentation",
  m_bus_meter_2_0108: 'Température de retour',
  m_bus_meter_2_0110: 'Énergie de refroidissement accumulée',
  m_bus_meter_2_0112: 'Volume accumulé',
  // Meter 3
  m_bus_meter_3_title: 'Compteur 3 (Électricité)',
  m_bus_meter_3_0200: 'Numéro de série',
  m_bus_meter_3_0202: 'Fabricant',
  m_bus_meter_3_0203: 'Type de compteur',
  m_bus_meter_3_0204: 'État',
  m_bus_meter_3_0206: 'Puissance',
  m_bus_meter_3_0210: 'Énergie accumulée',
  m_bus_meter_3_0214: 'Courant',
  // Meter 4
  m_bus_meter_4_title: 'Compteur 4 (Eau)',
  m_bus_meter_4_0300: 'Numéro de série',
  m_bus_meter_4_0302: 'Fabricant',
  m_bus_meter_4_0303: 'Type de compteur',
  m_bus_meter_4_0304: 'État',
  m_bus_meter_4_0305: 'Débit',
  m_bus_meter_4_0307: "Température d'alimentation (si disponible)",
  m_bus_meter_4_0312: 'Volume accumulé',
  // Meter 5
  m_bus_meter_5_title: 'Compteur 5 (Bidirectionnel)',
  m_bus_meter_5_0400: 'Numéro de série',
  m_bus_meter_5_0402: 'Fabricant',
  m_bus_meter_5_0403: 'Type de compteur',
  m_bus_meter_5_0404: 'État',
  m_bus_meter_5_0405: 'Débit',
  m_bus_meter_5_0406: 'Puissance',
  m_bus_meter_5_0407: "Température d'alimentation",
  m_bus_meter_5_0408: 'Température de retour',
  m_bus_meter_5_0410: 'Énergie de chauffage accumulée',
  m_bus_meter_5_0412: 'Volume accumulé',
  m_bus_meter_5_0414: 'Énergie de refroidissement accumulée',
  // History Block Errors
  history_block_errors_title:
           "Erreurs de Bloc d'Historique - triées par les plus récentes",
  history_block_errors_860: 'Alarme de Bloc 1',
  history_block_errors_861: 'Alarme de Bloc 2',
  history_block_errors_862: 'Alarme de Bloc 3',
  history_block_errors_863: 'Alarme de Bloc 4',
  history_block_errors_864: 'Alarme de Bloc 5',
  history_block_errors_865: 'Alarme de Bloc 6',
  history_block_errors_866: 'Alarme de Bloc 7',
  history_block_errors_867: 'Alarme de Bloc 8',
  history_block_errors_868: 'Alarme de Bloc 9',
  history_block_errors_869: 'Alarme de Bloc 10',
  history_block_errors_870: 'Alarme de Bloc 11',
  history_block_errors_871: 'Alarme de Bloc 12',
  history_block_errors_872: 'Alarme de Bloc 13',
  history_block_errors_873: 'Alarme de Bloc 14',
  history_block_errors_874: 'Alarme de Bloc 15',
  // Events
  device_events_type_filter_label: "Type d'événement",
  device_events_type_filter_value_1: 'Audit',
  device_events_type_filter_value_2: 'Alarme',
  // Presets
  presets: 'Préréglages',
  presets_all: 'Tous',
  presets_active: 'ACTIF',
  presets_inactive: 'INACTIF',
  presets_add_new: 'Ajouter nouveau',
  presets_filter_search: 'Recherche',
  presets_filter_reset_button: 'Réinitialiser',
  presets_filter_delete_button: 'Supprimer',
  presets_table_name: 'Nom',
  presets_table_status: 'Statut',
  presets_updated_date: 'Mis à jour le',
  presets_action: 'Actions',
  presets_table_row_per_page: 'Lignes par page',
  presets_status_menu_list: JSON.stringify([
    {
      key: 'settings_hot_water',
      value: 'Réglages eau chaude',
      permission: 'settings-hot-water-tab-read',
    },
    {
      key: 'settings_heating_and_cooling',
      value: 'Réglages chauffage et refroidissement',
      permission: 'settings-heating-and-cooling-tab-read',
    },
    {
      key: 'manual_control',
      value: 'Contrôle manuel',
      permission: 'manual-control-tab-read',
    },
  ]),
  add_presets_title: 'Ajouter préréglage',
  edit_presets_title: 'Modifier préréglage',
  presets_form_name_required: 'Le nom du préréglage est requis',
  presets_form_register_required:
           "La valeur d'enregistrement est requise",
  presets_form_add_preset_button: 'Enregistrer les modifications',
  presets_form_edit_preset_button: 'Enregistrer les modifications',
  presets_form_cancel_button: 'Annuler',
  presets_add_form_success: 'Préréglage créé avec succès !',
  presets_edit_form_success: 'Préréglage mis à jour avec succès !',
  presets_form_api_error:
           "Alarme du serveur interne de l'API - Préréglages",
  presets_no_record_found: 'Aucun préréglage trouvé',
  presets_deactivated_message: 'Le préréglage est désactivé avec succès',
  presets_activated_message: 'Le préréglage est activé avec succès',
  presets_bulk_no_user_is_selected_error_message:
           'Veuillez sélectionner des enregistrements à désactiver',
  presets_bulk_deactivated_message:
           '{{ USERS_COUNT }} préréglages sont désactivés avec succès',
  // Audits
  audits: 'Vérifications',
  audits_all: 'Tous',
  audits_active: 'Vérification',
  audits_inactive: 'Alarme',
  audits_filter_search: 'Recherche',
  audits_client_filter_label: 'Client',
  audits_error_code_filter_label: "Code d'alarme",
  audits_project_filter_label: 'Projet',
  audits_order_number_filter_label: 'Numéro de commande',
  audits_events_type_filter_label: "Type d'événement",
  audits_date_range_filter_label: 'Plage de dates',
  audits_events_type_filter_value_1: 'Vérification',
  audits_events_type_filter_value_2: 'Alarme',
  audits_reset_filters_button: 'Réinitialiser',
  audits_date: 'Date',
  audits_device: 'Appareil',
  audits_client: 'Client',
  audits_message: 'Message',
  audits_table_row_per_page: 'Lignes par page',
  audits_table_no_record_found: 'Aucun enregistrement trouvé',
  // Settings
  settings_update_profile_title: 'Mettre à jour le profil',
  settings_notification_title: 'Notifications',
  settings_notification_window_exceptions_title:
           "Notifier en cas d'exceptions de la fenêtre de fonctionnement",
  settings_notification_device_error_title:
           "Notifier lorsqu'un appareil est en mode d'erreur",
  settings_notification_channels_title: 'Canaux de notifications',
  settings_notification_use_email_title: "Utiliser l'email",
  /* Admins */
  admins: 'Administrateurs',
  admins_first_bread_crumb: 'Administrateurs',
  admins_filter_search: 'Recherche',
  admins_add_new: 'Ajouter un utilisateur',
  admins_filter_reset_button: 'Réinitialiser',
  admins_filter_delete_button: 'Désactiver',
  admins_search: 'Recherche',
  admins_all: 'TOUS',
  admins_active: 'ACTIF',
  admins_inactive: 'INACTIF',
  admins_table_name: 'Nom',
  admins_table_address: 'Adresse',
  admins_table_role_id: 'Rôle',
  admins_table_status: 'Statut',
  admins_table_added: 'Date de création',
  admins_table_no_record_found: 'Aucun enregistrement trouvé',
  admins_table_row_per_page: 'Lignes par page',
  admins_no_user_selected_for_de_activation_message:
           'Veuillez sélectionner des enregistrements à désactiver',
  admins_bread_crumb_main_title: 'Administrateurs',
  add_admins_bread_crumb_first_link: 'Ajouter administrateur',
  add_admins_form_header: 'Ajouter administrateur',
  edit_admins_bread_crumb_first_link: 'Modifier administrateur',
  edit_admins_form_header: 'Modifier administrateur',
  admins_form_first_name_field: 'Prénom',
  admins_form_last_name_field: 'Nom de famille',
  admins_form_email_field: 'Adresse email',
  admins_form_official_email_field: 'Adresse email officielle',
  admins_form_phone_field: 'Numéro de téléphone',
  admins_form_official_phone_number_field:
           'Numéro de téléphone officiel',
  admins_form_password_field: 'Mot de passe',
  admins_form_role_id_field: 'Rôle',
  admins_form_select_role_id_field: 'Sélectionner un rôle',
  admins_form_street_number_field: 'Rue',
  admins_form_house_number_field: 'Numéro de maison',
  admins_form_neighbourhood_field: 'Quartier',
  admins_form_postal_code_field: 'Code postal',
  admins_form_city_field: 'Ville',
  admins_form_country_field: 'Pays',
  admins_form_email_verification_field: "Vérification de l'email",
  admins_form_email_verification_sub_title_field:
           "La désactivation de ceci enverra automatiquement un email de vérification à l'administrateur",
  admins_form_add_admin_button: 'Enregistrer les modifications',
  admins_form_edit_admin_button: 'Enregistrer les modifications',
  admins_form_cancel_button: 'Annuler',
  admins_form_submitting_admin_button: 'En cours...',
  admins_first_name_required_form_error: 'Le prénom est requis',
  admins_last_name_required_form_error: 'Le nom de famille est requis',
  admins_email_required_form_error: "L'adresse email est requise",
  admins_email_valid_form_error: 'Doit être une adresse email valide',
  admins_password_required_form_error: 'Le mot de passe est requis',
  admins_country_required_form_error: 'Le pays est requis',
  admins_role_id_required_form_error: 'Le rôle est requis',
  admins_password_form_error:
           'Le mot de passe doit contenir au moins une lettre majuscule, un chiffre et un caractère spécial',
  admins_add_form_success: "L'administrateur est créé avec succès !",
  admins_edit_form_success:
           "L'administrateur est mis à jour avec succès !",
  admins_form_api_error:
           'Erreur interne du serveur API - Administrateurs',
  admins_form_email_error: "L'adresse email existe déjà",
  admins_no_record_found: 'Aucun administrateur trouvé',
  admins_deactivated_message:
           "L'administrateur est désactivé avec succès",
  admins_activated_message: "L'administrateur est activé avec succès",
  admins_email_verified_title: "L'email est vérifié",
  admins_email_verified_sub_title:
           "La désactivation de ceci enverra automatiquement un email de vérification à l'administrateur",
  admins_bulk_no_admin_is_selected_error_message:
           'Veuillez sélectionner des enregistrements à désactiver',
  admins_bulk_deactivated_message:
           '{{ USERS_COUNT }} administrateurs sont désactivés avec succès',
  // Search Results
  search_result_title: 'Résultats de la recherche : ',
  search_users: 'Utilisateurs',
  search_clients: 'Clients',
  search_service_clients: 'Clients de service',
  search_devices: 'Appareils',
  search_presets: 'Préréglages',
  search_projects: 'Projets',
  // New Locales
  no_error_devices_found: 'Aucun appareil en erreur trouvé',
  project_single_first_bread_crumb: 'Projets',
  project_single_second_bread_crumb: 'Voir le projet',
  project_single_client_label: 'Client',
  project_single_service_client_label: 'Client de service',
  project_single_devices_label: 'Appareils',
  project_single_edit_button: 'Modifier',
  project_single_devices_tab: 'Appareils',
  client_single_view_more_errors_button: 'Voir plus',
  client_error_single_devices_tab: 'Appareils en alarme',
  client_error_single_device_name: 'Appareil',
  client_error_single_device_error_code: "Code d'alarme",
  client_error_single_device_error_date_time: 'Date',
  devices_bulk_edit_registers_button: 'Modifier le(s) paramètre(s)',
  devices_bulk_edit_ota_button: 'Mise à jour du(des) appareil(s)',
  client_error_single_device_errors_table_row_per_page:
           'Lignes par page',
  client_error_single_device_error_no_record_found:
           'Aucun enregistrement trouvé',
  devices_bulk_edit_registers_title:
           "Mise à jour des paramètres d'appareil en vrac",
  devices_bulk_edit_registers_form_submit_button: 'Mise à jour groupée',
  devices_bulk_edit_registers_form_cancel_button: 'Annuler',
  devices_bulk_edit_registers_form_success:
           'Message transmis aux appareils sélectionnés',
  devices_bulk_edit_registers_form_error:
           'Veuillez remplir au moins une valeur de registre',
  devices_bulk_edit_registers_form_api_error:
           'Erreur interne du serveur',
  devices_bulk_edit_ota_title:
           "Mise à jour groupée du firmware d'appareil",
  devices_bulk_edit_ota_form_firmware_field: 'Firmware',
  devices_bulk_edit_ota_form_submit_button:
           'Mettre à jour les appareils',
  devices_bulk_edit_ota_form_cancel_button: 'Annuler',
  devices_bulk_edit_ota_form_success:
           'Message transmis aux appareils sélectionnés',
  devices_bulk_edit_ota_form_firmware_required_error: 'Firmware requis',
  devices_bulk_edit_ota_form_api_error:
           'Erreur interne du serveur en vrac',
  devices_bulk_edit_registers_selected_devices:
           'Appareils sélectionnés pour la mise à jour groupée',
  // Device Status Table Pop Over
  custom_popover_min_value: 'Valeur min.',
  custom_popover_max_value: 'Valeur max.',
  custom_popover_list_options: 'Valeurs possibles',
  custom_popover_short_description: 'Description',
  custom_popover_close_modal_button: 'Fermer la fenêtre',
  // Warmtapwater PopOver Short Description
  custom_popover_300_short_description:
           "Température de consigne d'eau chaude",
  custom_popover_301_short_description: 'Mode de préchauffage',
  custom_popover_302_short_description:
           'Température de consigne de confort',
  custom_popover_303_short_description: 'Seuil de débit minimum',
  custom_popover_304_short_description: "Activation de l'eau chaude",
  // Thermal disinfection PopOver Short Description
  custom_popover_400_short_description: 'Désinfection thermique activée',
  custom_popover_401_short_description:
           'Point de consigne de désinfection thermique',
  custom_popover_402_short_description:
           'Temps de brûlage de la désinfection thermique',
  custom_popover_403_short_description:
           "Temps d'attente de la désinfection thermique",
  // TE-Booster PopOver Short Description
  custom_popover_1800_short_description: 'TE-Booster activé',
  custom_popover_1804_short_description: 'Point de consigne TE-Booster',
  custom_popover_1805_short_description:
           'Hystérésis du point de consigne TE-Booster',
  custom_popover_1812_short_description:
           'Délai du point de consigne TE-Booster',
  custom_popover_1813_short_description:
           'Minuteur du point de consigne TE-Booster',
  custom_popover_1815_short_description: 'Seuil TE-Booster',
  custom_popover_1816_short_description: 'Volume enregistré TE-Booster',
  // Heating PopOver Short Description
  custom_popover_500_short_description:
           'Température de consigne maximale de chauffage',
  custom_popover_501_short_description:
           'Température de consigne minimale de chauffage',
  custom_popover_506_short_description: 'Retour primaire maximal',
  custom_popover_507_short_description:
           'Facteur de compensation de retour primaire',
  custom_popover_508_short_description:
           'Écart de température entre primaire et secondaire',
  custom_popover_510_short_description:
           'Température maximale de chauffage',
  custom_popover_509_short_description:
           'Écart minimal entre primaire et secondaire',
  custom_popover_514_short_description:
           'Température de consigne de chauffage',
  custom_popover_515_short_description: 'Activation du chauffage',
  // Cooling PopOver Short Description
  custom_popover_600_short_description:
           'Température de consigne de refroidissement',
  custom_popover_601_short_description: 'Retour primaire minimal',
  custom_popover_602_short_description:
           'Facteur de compensation de retour primaire',
  custom_popover_605_short_description:
           'Température maximale de refroidissement',
  custom_popover_606_short_description:
           'Température de consigne minimale de refroidissement',
  custom_popover_607_short_description:
           'Température de consigne maximale de refroidissement',
  custom_popover_611_short_description: 'Activation du refroidissement',
  // Secondary General PopOver Short Description
  custom_popover_512_short_description: 'Pression minimale',
  custom_popover_513_short_description: 'Pression maximale',
  custom_popover_603_short_description:
           "Temps d'attente pour basculer entre chauffage et refroidissement",
  custom_popover_604_short_description:
           "Temps d'attente de la vanne 6 voies",
  // Changeover WKW PopOver Short Description
  custom_popover_1850_short_description:
           'Configuration du système 3.5 tuyaux',
  custom_popover_1851_short_description:
           'Seuil de détection du refroidissement',
  custom_popover_1852_short_description:
           'Seuil de détection du chauffage',
  custom_popover_1853_short_description:
           'Point de consigne réduit pour le chauffage',
  custom_popover_1854_short_description:
           'Point de consigne réduit utilisé',
  custom_popover_1855_short_description: 'Position de prédétection M2',
  custom_popover_1856_short_description: 'Intervalle de détection',
  custom_popover_1857_short_description:
           "Minuterie d'intervalle de détection",
  custom_popover_1858_short_description: 'Délai de prédétection',
  custom_popover_1859_short_description: 'Minuterie de prédétection',
  custom_popover_1860_short_description:
           'Minuterie de commutation de configuration',
  custom_popover_1861_short_description: 'Délai de commutation',
  custom_popover_1862_short_description:
           'Seuil de commutation de configuration',
  custom_popover_1863_short_description:
           'Mode de fonctionnement de renforcement',
  custom_popover_1864_short_description:
           'Minuterie de détection de renforcement',
  custom_popover_1865_short_description:
           'Délai de détection de renforcement',
  custom_popover_1866_short_description:
           'Position de renforcement maximale',
  // Manual Operation PopOver Short Description
  custom_popover_700_short_description: 'Activation du contrôle manuel',
  custom_popover_701_short_description: 'Van M1 eau chaude',
  custom_popover_702_short_description:
           'Van M2 chauffage/refroidissement',
  custom_popover_703_short_description: 'Pompe',
  custom_popover_704_short_description: 'Van 6 voies',
  custom_popover_706_short_description:
           "Sortie d'état de la demande de chauffage",
  custom_popover_707_short_description: 'Van M1 eau chaude [%]',
  custom_popover_708_short_description:
           'Van M2 chauffage/refroidissement [%]',
  custom_popover_1801_short_description: 'Élément électrique TE-Booster',
  custom_popover_709_short_description:
           'Minuterie de temporisation de contrôle manuel',
  // Warmtapwater PopOver Long Description
  custom_popover_300_long_description: 'Point de consigne ECS',
  custom_popover_301_long_description: 'Mode de préchauffage',
  custom_popover_302_long_description: 'Point de consigne confort',
  custom_popover_303_long_description: 'Seuil de démarrage du débit',
  custom_popover_304_long_description: "Activation de l'ECS",
  // Thermal disinfection PopOver Long Description
  custom_popover_400_long_description:
           'Activation de la désinfection thermique',
  custom_popover_401_long_description:
           'Point de consigne de désinfection thermique',
  custom_popover_402_long_description:
           'Durée de combustion de désinfection thermique',
  custom_popover_403_long_description:
           "Temps d'attente de désinfection thermique",
  // TE-Booster PopOver Long Description
  custom_popover_1800_long_description: 'Activation du TE-Booster',
  custom_popover_1804_long_description:
           'Point de consigne du TE-Booster',
  custom_popover_1805_long_description:
           'Hystérésis du point de consigne du TE-Booster',
  custom_popover_1812_long_description:
           "Délai d'expiration du point de consigne du TE-Booster",
  custom_popover_1813_long_description:
           'Minuteur du point de consigne du TE-Booster',
  custom_popover_1815_long_description: 'Seuil du TE-Booster',
  custom_popover_1816_long_description:
           'Volume enregistré du TE-Booster',
  // Heating PopOver Long Description
  custom_popover_500_long_description:
           'Température de consigne maximale du chauffage',
  custom_popover_501_long_description:
           'Température de consigne minimale du chauffage',
  custom_popover_506_long_description: 'Retour primaire maximal',
  custom_popover_507_long_description:
           'Facteur de compensation Retour primaire',
  custom_popover_508_long_description: 'Différence de consigne Prim/CH',
  custom_popover_510_long_description:
           'Température maximale de chauffage',
  custom_popover_509_long_description: 'Différence minimale Prim/CH',
  custom_popover_514_long_description:
           'Température de consigne du chauffage',
  custom_popover_515_long_description: 'Activation du chauffage',
  // Cooling PopOver Long Description
  custom_popover_600_long_description:
           'Température de consigne de refroidissement',
  custom_popover_601_long_description:
           'Retour primaire maximal du refroidissement',
  custom_popover_602_long_description:
           'Facteur de compensation % de la consigne maximale',
  custom_popover_605_long_description:
           'Température maximale de refroidissement',
  custom_popover_606_long_description:
           'Température de consigne minimale de refroidissement',
  custom_popover_607_long_description:
           'Température de consigne maximale de refroidissement',
  custom_popover_611_long_description: 'Activation du refroidissement',
  // Secondary General PopOver Long Description
  custom_popover_512_long_description: 'Pression minimale du chauffage',
  custom_popover_513_long_description: 'Pression maximale du chauffage',
  custom_popover_603_long_description:
           "Temps d'attente pour passer du chauffage au refroidissement",
  custom_popover_604_long_description:
           "Temps d'attente de la vanne à 6 voies",
  // Changeover WKW PopOver Long Description
  custom_popover_1850_long_description: 'Configuration de 3,5 tuyaux',
  custom_popover_1851_long_description:
           'Seuil de détection du refroidissement',
  custom_popover_1852_long_description:
           'Seuil de détection du chauffage',
  custom_popover_1853_long_description:
           'Point de consigne de chauffage réduit',
  custom_popover_1854_long_description:
           'Point de consigne réduit utilisé',
  custom_popover_1855_long_description: 'Position MCV2 de prédétection',
  custom_popover_1856_long_description: 'Intervalle de détection',
  custom_popover_1857_long_description:
           "Minuterie d'intervalle de détection",
  custom_popover_1858_long_description: 'Délai de prédétection',
  custom_popover_1859_long_description: 'Minuterie de prédétection',
  custom_popover_1860_long_description:
           'Minuterie de commutation de configuration',
  custom_popover_1861_long_description: 'Délai de commutation',
  custom_popover_1862_long_description:
           'Seuil de commutation de configuration',
  custom_popover_1863_long_description: 'Mode de fonction Boost',
  custom_popover_1864_long_description:
           'Minuterie de détection du Boost',
  custom_popover_1865_long_description: 'Délai de détection du Boost',
  custom_popover_1866_long_description: 'Position de Boost maximale',
  // Manual Operation PopOver Long Description
  custom_popover_700_long_description: 'Contrôle manuel activé',
  custom_popover_701_long_description: 'Contrôle manuel VMC-1',
  custom_popover_702_long_description: 'Contrôle manuel VMC-2',
  custom_popover_703_long_description: 'Contrôle manuel Pompe (P1)',
  custom_popover_704_long_description: 'Valve1',
  custom_popover_706_long_description:
           "Sortie d'état de demande de chaleur",
  custom_popover_707_long_description: 'Contrôle manuel VMC-1 [%]',
  custom_popover_708_long_description: 'Contrôle manuel VMC-2 [%]',
  custom_popover_1801_long_description: 'Élément électrique TE-Booster',
  custom_popover_709_long_description: 'Minuterie de contrôle manuel',
  // API keys
  client_single_api_key_approve_api_key_label: 'Clé API',
  client_single_api_key_app_id_label: 'Identifiant App',
  client_single_api_key_approve_api_key_button: 'Approuver le Compte',
  client_single_api_key_suspend_api_key_button:
           "Suspendre l'Application",
  client_single_api_key_unblock_application_button:
           "Débloquer l'Application",
  client_single_api_key_generate_api_key_button: 'Générer une Clé API',
  client_single_api_key_generate_api_key_success:
           'La Clé API a été générée avec succès',
  client_single_api_key_generate_api_limit_error:
           'Désolé, nous ne pouvons pas régénérer une nouvelle Clé API car la limite de régénération est atteinte.',
  client_single_api_key_generate_api_3scale_error:
           "Alarme API 3Scale. Veuillez contacter l'administrateur.",
  client_single_api_application_suspend_success:
           "L'application 3Scale a été suspendue avec succès",
  client_single_api_application_unblock_success:
           "L'application 3Scale a été débloquée avec succès",
  client_single_api_application_suspend_error:
           "L'application est déjà suspendue",
  client_single_api_application_unblock_error:
           "L'application est déjà débloquée",
  client_single_api_key_account_pending_approval:
           "Votre compte n'a pas encore été approuvé. Veuillez contacter l'administrateur pour plus de détails.",
  client_single_api_key_account_suspended:
           "Votre compte est suspendu. Veuillez contacter l'administrateur pour plus de détails.",
  // Clients Form
  three_scale_temporary_password_subject:
           'Fortes Energy - Compte 3Scale Créé',
  clients_form_password_field: 'Mot de passe',
  clients_password_required_form_error:
           'Le mot de passe doit contenir au moins une lettre majuscule, un chiffre et un caractère spécial',
  // Devices Form
  devices_form_mbus_meters_are_not_connected:
           'Les compteurs MBus ne sont pas connectés.',
  MBUS_METER_HEATING: 'Compteur de chaleur',
  MBUS_METER_COOLING: 'Compteur de froid',
  MBUS_METER_ELECTRICITY: "Compteur d'électricité",
  MBUS_METER_WATER: "Compteur d'eau",
  MBUS_METER_BI_DIRECTIONAL: 'Compteur de chaleur/froid',
  meter_enabled: 'Activé',
  meter_disabled: 'Désactivé',
  devices_mbus_registers_toggle_register_status_success:
           "Le message a été envoyé avec succès à l'appareil.",
  MBUS_METER_SERIAL_NUMBER: 'Numéro de série',
  MBUS_METER_MANUFACTURER: 'Fabricant',
  MBUS_METER_METER_TYPE: 'Type de compteur',
  MBUS_METER_STATUS: 'Statut',
  MBUS_METER_FLOW: 'Débit',
  MBUS_METER_POWER: 'Puissance',
  MBUS_METER_SUPPLY_TEMPERATURE: 'Température de fourniture',
  MBUS_METER_RETURN_TEMPERATURE: 'Température de retour',
  MBUS_METER_HEATING_ENERGY: 'Énergie de chauffage accumulée',
  MBUS_METER_ELECTRICITY_ENERGY: 'Électricité accumulée',
  MBUS_METER_VOLUME: 'Volume accumulé',
  MBUS_METER_COOLING_ENERGY: 'Énergie de refroidissement accumulée',
  MBUS_METER_CURRENT: 'Courant',
  custom_popover_MBUS_METER_SERIAL_NUMBER_short_description:
           'Numéro de série',
  custom_popover_MBUS_METER_MANUFACTURER_short_description: 'Fabricant',
  custom_popover_MBUS_METER_METER_TYPE_short_description:
           'Type de compteur',
  custom_popover_MBUS_METER_STATUS_short_description: 'Statut',
  custom_popover_MBUS_METER_FLOW_short_description: 'Débit',
  custom_popover_MBUS_METER_POWER_short_description: 'Puissance',
  custom_popover_MBUS_METER_SUPPLY_TEMPERATURE_short_description:
           'Température de fourniture',
  custom_popover_MBUS_METER_RETURN_TEMPERATURE_short_description:
           'Température de retour',
  custom_popover_MBUS_METER_HEATING_ENERGY_short_description:
           'Énergie de chauffage accumulée',
  custom_popover_MBUS_METER_ELECTRICITY_ENERGY_short_description:
           'Électricité accumulée',
  custom_popover_MBUS_METER_VOLUME_short_description: 'Volume accumulé',
  custom_popover_MBUS_METER_COOLING_ENERGY_short_description:
           'Énergie de refroidissement accumulée',
  custom_popover_MBUS_METER_CURRENT_short_description: 'Courant',
  // Device Set Registers Per Device
  device_single_device_registers_tab: 'Définir les registres',
  device_single_device_registers_menu_list: JSON.stringify([
    {
      key: 'operating_state',
      value: 'État de fonctionnement',
      permission: 'operating-state-tab-read',
    },
  ]),
  device_status_interval: 'Intervalle',
  device_status_interval_in_seconds: 'Secondes',
  device_single_set_registers_error:
           'Veuillez sélectionner au moins un registre pour continuer.',
  device_single_set_registers_success:
           "Le message a été transmis avec succès à l'appareil.",
  device_single_set_register_form_interval_required_error:
           "L'intervalle (en secondes) est requis",
  device_single_set_register_form_interval_numeric_error:
           "L'intervalle (en secondes) doit être numérique",
  device_single_set_register_form_send_button: 'Envoyer',
  device_single_set_register_form_cancel_button: 'Annuler',
  clients_3scale_email_error: "L'adresse e-mail existe déjà dans 3Scale",
  page_title_appending_title: ' | Gestion des données Fortes',
  project_single_device_registers_tab: 'Définir les registres',
  audits_user: 'Utilisateur',
  audits_system_generated: 'Application',
  device_single_project_set_registers_error:
           "Le message n'est pas transmis car aucun appareil n'est lié au projet",
  audits_user_filter_label: 'Utilisateur',
  audits_event_type_field: "Type d'événement",
  audits_select_event_type_field: "Sélectionner le type d'événement",
  audits_select_event_type_incoming_option: 'Entrant',
  audits_select_event_type_outgoing_option: 'Sortant',
  audits_select_event_type_system_generated_option: 'Application',
  audits_table_loading: 'Chargement des événements',
  appliance_type_reference_table_appliance_name: 'Nom',
  appliance_type_reference_table_appliance_type: "Type d'appareil",
  appliance_type_reference_table_updated_at: 'Mis à jour le',
  sidebar_appliance_type_reference_menu: "Types d'appareils",
  appliance_types_register_form_send_button: 'Enregistrer',
  appliance_types_register_form_cancel_button: 'Annuler',
  appliance_types: "Types d'appareils",
  appliance_types_table_no_record_found: 'Aucun enregistrement trouvé',
  appliance_types_table_row_per_page: 'Lignes par page',
  add_appliance_type: "Ajouter un type d'appareil",
  add_appliance_types_form_header: "Ajouter un type d'appareil",
  edit_appliance_types_form_header: "Modifier un type d'appareil",
  appliance_types_add_new: 'Ajouter un nouveau',
  appliance_type_form_name_label: 'Nom',
  appliance_type_form_type_label: "Type d'appareil",
  appliance_type_form_select_type_label:
           "Sélectionner un type d'appareil",
  appliance_name_form_required_error: 'Le nom est requis',
  appliance_type_form_required_error: "Le type d'appareil est requis",
  add_appliance_type_form_success:
           "L'enregistrement du type d'appareil a été créé avec succès",
  add_appliance_type_form_api_error: 'Erreur du serveur interne',
  edit_appliance_type_form_success:
           "L'enregistrement du type d'appareil a été mis à jour avec succès",
  edit_appliance_type_form_api_error: 'Erreur du serveur interne',
  appliance_type_reference_table_filter_reset_button: 'Réinitialiser',
  appliance_type_reference_table_search: 'Rechercher',
  appliance_type_reference_status_menu_list: JSON.stringify([
    {
      key: 'operating_state',
      value: 'État de fonctionnement',
      permission: 'lecture-onglet-état-de-fonctionnement',
    },
    {
      key: 'settings_hot_water',
      value: 'Réglages eau chaude',
      permission: 'lecture-onglet-réglages-eau-chaude',
    },
    {
      key: 'settings_heating_and_cooling',
      value: 'Réglages chauffage et refroidissement',
      permission: 'lecture-onglet-réglages-chauffage-et-refroidissement',
    },
    {
      key: 'manual_control',
      value: 'Contrôle manuel',
      permission: 'lecture-onglet-contrôle-manuel',
    },
  ]),
  audits_filter_button: 'Filtres',
  device_single_no_registers_are_available:
           "Aucun registre n'est disponible",
  device_single_device_diagnostics_tab: 'Diagnostics',
  device_single_dp_measurement_back_button: 'Précédent',
  device_single_diagnostic_next_button: 'Suivant',
  device_single_dp_measurement_start_button: 'Démarrer',
  device_single_diagnostict_finish_button: 'Terminer',
  device_single_diagnostic_stop_button: 'Arrêter',
  device_single_dp_measurement_refresh_button: 'Actualiser',
  device_single_dp_measurement_event_logs_title: 'Sortie',
  device_single_dp_measurement_select_mbus_meter_button:
           'Sélectionner un compteur M-Bus',
  device_single_dp_measurement_step_1_title:
           'Calcul de la pression différentielle - Choisissez le débit',
  device_single_dp_measurement_pressure_difference_stepper_1_title:
           'Choisissez le débit',
  device_single_dp_measurement_step_1_flow_1_label:
           'Calcul de la pression différentielle',
  device_single_dp_measurement_step_1_flow_2_label:
           'Eau chaude sanitaire',
  device_single_dp_measurement_pressure_difference_step_2_title:
           "Calcul de la pression différentielle - Explication de l'assistant",
  device_single_dp_measurement_pressure_difference_stepper_2_title:
           "Explication de l'assistant",
  device_single_dp_measurement_pressure_difference_step_2_description:
           "La pression différentielle disponible est toujours un problème dans les réseaux collectifs. Grâce à la puissante combinaison d'AquaHeat aflversets et de la plateforme Fortes Data Management, vous pouvez facilement effectuer un calcul de pression différentielle à n'importe quel point de votre réseau collectif grâce à un module convivial. Cela donne une excellente première indication d'une cause possible. Avec ce diagnostic, nous vous permettons de calculer une indication initiale de la différence de pression disponible. <br /><br /><strong>Requis : Le compteur de chaleur doit être connecté au module de communication Mercury</strong>",
  device_single_dp_measurement_pressure_difference_step_3_title:
           'Calcul de la pression différentielle - Contrôle des bons composants',
  device_single_dp_measurement_pressure_difference_stepper_3_title:
           'Registre de commande manuelle',
  device_single_dp_measurement_step_3_pressure_difference_output_1:
           'Sortie 1 - Activation du contrôle manuel',
  device_single_dp_measurement_pressure_difference_output_2:
           'Sortie 2 - Ouverture de la vanne de contrôle',
  device_single_dp_measurement_pressure_difference_step_3_mbus_meter_title:
           "Sélectionner le compteur M-Bus (pour plusieurs compteurs, il s'agit du compteur d'énergie qui enregistre l'eau potable.)",
  device_single_dp_measurement_pressure_difference_stepper_4_title:
           'Sélectionner le bon compteur M-Bus',
  device_single_dp_measurement_pressure_difference_step_4_flow_label:
           'Débit',
  device_single_dp_measurement_pressure_difference_stepper_5_title:
           'Calcul de la pression différentielle',
  device_single_dp_measurement_pressure_difference_output_3:
           'Sortie 3 - Sélectionner le compteur',
  device_single_dp_measurement_pressure_difference_output_4:
           'Sortie 4 - Calcul de la pression différentielle',
  device_single_dp_measurement_pressure_difference_stepper_6_title:
           'Résultat',
  device_single_dp_measurement_pressure_difference_step_6_description:
           "Votre pression différentielle est OK ! Aucune action supplémentaire n'est nécessaire.",
  device_single_dp_measurement_download_button:
           'Télécharger les résultats',
  device_single_dp_measurement_pressure_difference_step_7_description:
           'Le calcul de la pression différentielle a été effectué avec succès.',
  device_single_general_title: 'Général',
  device_single_hotwater_title: 'Eau chaude sanitaire',
  device_single_dp_measurement_hot_water_flow_1_stepper_1_title:
           'Choisir le débit',
  device_single_dp_measurement_hot_water_flow_1_stepper_2_title:
           "Explication de l'assistant",
  device_single_dp_measurement_hot_water_flow_1_stepper_3_title:
           'Ouvrir le robinet',
  device_single_dp_measurement_hot_water_flow_1_stepper_4_title:
           "Vérification du robinet et de la vanne d'eau chaude",
  device_single_dp_measurement_hot_water_flow_1_stepper_5_title:
           'Compteur M-Bus',
  device_single_dp_measurement_hot_water_flow_1_stepper_6_title:
           "Calcul du débit d'eau chaude",
  device_single_dp_measurement_hot_water_flow_1_stepper_7_title:
           'Résultats',
  device_single_dp_measurement_hot_water_flow_1_step_2_title:
           'Mesure de la pression différentielle - Explication du débit',
  device_single_dp_measurement_hot_water_flow_1_step_2_description:
           'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu proin amet vitae etiam. In mauris habitasse turpis quis tortor, integer magna dictum condimentum. In pellentesque proin eu ut viverra quam. Consequat pharetra proin lacus, ultrices enim. Tincidunt porta tortor lacinia varius mattis pellentesque. In augue sit vitae leo sed. Nibh sed mi bibendum convallis viverra vestibulum pretium commodo. Nibh quam vulputate malesuada sodales. Curabitur nisl leo quis sed vitae cras. Tempor risus, a volutpat consequat eget faucibus nulla fermentum. Ut dignissim proin nulla et congue mattis. Euismod et ipsum quis habitant in enim viverra. Mattis varius quis arcu cras vestibulum nunc mattis consectetur in. Tempus mauris egestas elit malesuada ullamcorper aliquet cras adipiscing ',
  device_single_dp_measurement_hot_water_flow_1_step_3_title:
           'Ouvrir le robinet',
  device_single_dp_measurement_hot_water_flow_1_step_3_faucet_error:
           'Défaut du capteur de débit',
  device_single_dp_measurement_hot_water_flow_1_step_3_faucet_error_one_try_left:
           'Essayez une fois de plus',
  diagnostic_flows_form_open_faucet_title:
           'Le robinet est ouvert par le client',
  diagnostic_flows_form_open_faucet_off_option_description:
           "Le robinet n'est pas ouvert par le client",
  device_single_dp_measurement_hot_water_flow_1_output_1:
           "Sortie 1 - État de l'eau chaude",
  device_single_dp_measurement_hot_water_flow_1_output_2:
           "Sortie 2 - Vanne d'eau chaude (M1)",
  device_single_dp_measurement_hot_water_flow_1_step_3_mbus_meter_title:
           'Sélectionner le compteur M-Bus',
  device_single_dp_measurement_hot_water_flow_1_step_4_flow_label:
           'Débit',
  device_single_dp_measurement_step_4_hot_water_flow_1_output_3:
           'Sortie 3 - Sélectionner le compteur',
  device_single_dp_measurement_hot_water_flow_1_output_4:
           'Sortie 4 - Débit Mbus',
  device_single_dp_measurement_hot_water_flow_1_output_5:
           "Sortie 5 - Calcul du débit d'eau chaude",
  device_single_dp_measurement_hot_water_flow_1_step_7_description:
           'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu proin amet vitae etiam. In mauris habitasse turpis quis tortor, integer magna dictum condimentum. In pellentesque proin eu ut viverra quam. Consequat pharetra proin lacus, ultrices enim. Tincidunt porta tortor lacinia varius mattis pellentesque. In augue sit vitae leo sed. Nibh sed mi bibendum convallis viverra vestibulum pretium commodo. Nibh quam vulputate malesuada sodales. Curabitur nisl leo quis sed vitae cras. Tempor risus, a volutpat consequat eget faucibus nulla fermentum. Ut dignissim proin nulla et congue mattis. Euismod et ipsum quis habitant in enim viverra. Mattis varius quis arcu cras vestibulum nunc mattis consectetur in. Tempus mauris egestas elit malesuada ullamcorper aliquet cras adipiscing ',
  device_single_dp_measurement_hot_water_flow_1_step_8_description:
           "Les diagnostics du débit d'eau chaude - Flow 1 sont terminés",
  device_single_heating_title: 'Chauffage',
  device_single_dp_measurement_step_1_flow_3_label: 'Débit 1',
  device_single_cooling_title: 'Refroidissement',
  device_single_dp_measurement_step_1_flow_4_label: 'Débit 1',
  device_single_temperature_graph_loading_data:
           'Chargement du graphique',
  device_single_temperature_no_graph_data_error:
           "Le graphique n'est pas disponible",
  device_graph_week_filter: 'S',
  device_graph_weeks_filter: 'S',
  device_graph_day_filter: 'J',
  device_graph_days_filter: 'J',
  device_graph_hours_filter: 'H',
  device_single_registers_table_numeric_error:
           'Seules les valeurs numériques sont autorisées',
  device_single_registers_table_min_max_error:
           'La valeur doit être entre min_value et max_value',
  MBUS_METER_TYPE_WATER: 'Eau',
  MBUS_METER_TYPE_ELECTRICITY: 'Électricité',
  MBUS_METER_TYPE_HEATING: 'Chaleur',
  MBUS_METER_TYPE_BIDIRECTIONAL: 'Chaleur/Froid',
  MBUS_METER_TYPE_COOLING: 'Refroidissement',
  UNKNOWN_MANUFACTURER: 'Fabricant inconnu',
  commissoning_form_header: 'Mise en service',
  commissoning_form_serial_number_scan_button: 'Scanner',
  commissoning_form_heat_interface_unit_scan_button:
           "Scanner l'unité d'interface de chauffage",
  commissoning_form_search_field: 'Numéro de série Mercurius',
  sidebar_commissioning_overview: 'Aperçu de la mise en service',
  sidebar_commissioning: 'Mise en service',
  commissoning_form_success: "L'appareil a été mis à jour avec succès !",
  commissoning_error_message: 'Appareil non trouvé',
  commissoning_device_submit_button: "Mettre à jour l'appareil",
  swapping_form_header: "Remplacement d'appareil",
  swapping_old_device_title: 'Ancien appareil',
  swapping_new_device_title: 'Nouvel appareil',
  swapping_form_scan_button: 'Scanner',
  swapping_form_search_field: 'Numéro de série Mercurius',
  sidebar_swapping_overview: "Aperçu du remplacement d'appareil",
  sidebar_swapping: 'Remplacement',
  swapping_form_success: "L'appareil a été remplacé avec succès !",
  swapping_old_device_error_message: 'Ancien appareil non trouvé',
  swapping_new_device_error_message: 'Nouvel appareil non trouvé',
  swapping_audit_message: "L'appareil a été remplacé",
  swapping_device_submit_button: 'Remplacer les appareils',
  email_device_swapping_subject:
           "Fortes Data Management - Remplacement d'appareil",
  email_device_swapping_recipient_name: 'Fortes Data Management',
  email_device_swapping_title:
           "Fortes Data Management - Remplacement d'appareil",
  email_device_swapping_old_device_title: 'Ancien appareil',
  email_device_swapping_new_device_title: 'Nouvel appareil',
  email_device_swapped_by: 'Appareil remplacé par :',
  swapping_device_unique_error: "L'appareil existe déjà",
  clear_device_form_header: "Effacer l'appareil",
  clear_device_form_scan_button: 'Scanner',
  clear_device_form_search_field: 'Numéro de série Mercurius',
  sidebar_clear_device_overview: "Effacer l'appareil",
  sidebar_clear_device: "Effacer l'appareil",
  clear_device_form_success: "L'appareil a été effacé avec succès !",
  clear_device_error_message: 'Appareil non trouvé',
  clear_device_submit_button: "Effacer l'appareil",
  clear_device_audit_message: "L'appareil a été effacé",
  device_single_swapped_devices_tab: 'Appareils remplacés',
  devices_single_swapped_device_name: "Nom de l'appareil",
  devices_single_swapped_device_firmware: 'Micrologiciel',
  devices_single_swapped_device_type: "Type d'appareil",
  devices_single_swapped_device_box_serial_number:
           "Numéro de série de l'unité d'interface de chauffage",
  devices_single_swapped_device_sim_card_number: 'Numéro de carte SIM',
  devices_single_swapped_device_created_at: 'Date de remplacement',
  devices_single_swapped_device_name_search: 'Recherche',
  devices_single_swapped_swapped_device_name: 'Remplacé par',
  devices_single_swapped_device_table_no_record_found:
           'Aucun enregistrement trouvé',
  devices_single_swapped_device_table_loading: 'Chargement',
  devices_single_swapped_device_table_row_per_page: 'Lignes par page',
  sidebar_swapped_devices_overview: 'Aperçu des appareils remplacés',
  sidebar_swapped_devices: 'Appareils remplacés',
  swapped_devices_form_header: 'Appareils remplacés',
  devices_bulk_edit_registers_form_no_devices_error:
           'Veuillez sélectionner des appareils pour continuer.',
  devices_bulk_edit_firmware_modal_title:
           'Êtes-vous sûr de vouloir effectuer une mise à jour groupée ?',
  devices_bulk_edit_firmware_modal_subtitle:
           'Voulez-vous effectuer une mise à jour groupée de la version du micrologiciel ##firmware_value## pour ##total_devices## appareils ?',
  devices_bulk_edit_registers_modal_title:
           'Êtes-vous sûr de vouloir effectuer une mise à jour groupée ?',
  devices_bulk_edit_registers_modal_subtitle:
           'Voulez-vous effectuer une mise à jour groupée des registres pour ##total_devices## appareils ?',
  devices_bulk_edit_registers_modal_cancel_button: 'Annuler',
  devices_bulk_edit_registers_modal_submit_button: 'Confirmer',
  devices_bulk_edit_firmware_modal_cancel_button: 'Annuler',
  devices_bulk_edit_firmware_modal_submit_button: 'Confirmer',
  devices_bulk_export_button: 'Exporter les appareils',
  projects_bulk_export_button: 'Exporter les projets',
  project_devices_bulk_export_button: 'Exporter les appareils de projet',
  devices_bulk_export_file_name: "Exportation groupée d'appareils",
  projects_bulk_export_file_name: 'Exportation groupée de projets',
  project_devices_bulk_export_file_name:
           "Exportation groupée d'appareils",
  sidebar_reporting_overview: 'Rapports',
  sidebar_bulk_edit_ota_reporting: 'Rapport OTA',
  sidebar_bulk_reporting_registers_table_title: 'Rapport des registres',
  edit_ota_reporting: 'Audit OTA',
  reporting_ota_bulk_export_button: 'Exporter',
  reporting_ota_filter_reset_button: 'Réinitialiser',
  reporting_ota_table_filter_search: 'Recherche',
  reporting_ota_table_updated_at: 'Date',
  reporting_ota_table_total_devices: 'Total des appareils',
  reporting_ota_table_user: 'Utilisateur',
  reporting_ota_table_message: 'Message',
  reporting_ota_table_no_record_found: 'Aucun enregistrement trouvé',
  reporting_ota_table_row_per_page: 'Lignes par page',
  edit_ota_reporting_details: "Détails de l'audit OTA",
  reporting_ota_details_all_tab: 'Tout',
  reporting_ota_details_pending_tab: 'En attente',
  reporting_ota_details_success_tab: 'Succès',
  reporting_ota_details_failure_tab: 'Échec',
  reporting_ota_details_bulk_export_button: 'Exporter',
  reporting_ota_details_filter_reset_button: 'Réinitialiser',
  reporting_ota_details_table_filter_search: 'Recherche',
  reporting_ota_details_message:
           'La version du micrologiciel a été mise à jour en',
  reporting_ota_details_table_created_at: 'Date',
  reporting_ota_details_table_device: 'Appareil',
  reporting_ota_details_table_user: 'Utilisateur',
  reporting_ota_details_table_old_firmware_version:
           'Ancienne version du micrologiciel',
  reporting_ota_details_table_current_firmware_version:
           'Version actuelle du micrologiciel',
  reporting_ota_details_table_current_status: 'Statut',
  reporting_ota_details_table_message: 'Message',
  reporting_ota_details_table_no_record_found:
           'Aucun enregistrement trouvé',
  reporting_ota_details_table_row_per_page: 'Lignes par page',
  reporting_ota_bulk_export_file_name: 'Rapport OTA groupé',
  reporting_ota_details_bulk_export_file_name:
           'Détails du rapport OTA groupé',
  devices_single_swapped_bulk_export_button_file_name:
           "Rapport d'appareils remplacés",
  devices_single_swapped_bulk_export_button: 'Exporter',
  reporting_registers_table_title: "Rapport d'audit des registres",
  reporting_registers_table_created_at: 'Date',
  reporting_registers_table_total_devices: 'Total des appareils',
  reporting_registers_table_user: 'Utilisateur',
  reporting_registers_table_message: 'Message',
  reporting_registers_bulk_export_button: 'Exporter',
  reporting_registers_filter_reset_button: 'Réinitialiser',
  reporting_registers_table_filter_search: 'Recherche',
  reporting_registers_table_no_record_found:
           'Aucun enregistrement trouvé',
  reporting_registers_table_row_per_page: 'Lignes par page',
  reporting_registers_table_bulk_export_file_name:
           'Rapport groupé des registres',
  registers_reporting_details_title: "Détails de l'audit des registres",
  reporting_registers_details_bulk_export_button: 'Exporter',
  reporting_registers_details_filter_reset_button: 'Réinitialiser',
  reporting_registers_details_table_filter_search: 'Recherche',
  reporting_registers_details_table_created_at: 'Date',
  reporting_registers_details_table_device: 'Appareil',
  reporting_registers_details_table_user: 'Utilisateur',
  reporting_registers_details_table_message: 'Message',
  reporting_registers_details_table_failed_message:
           "La version du micrologiciel n'a pas été mise à jour en",
  reporting_registers_details_table_pending_message:
           'Mise à jour de la version du micrologiciel en cours en',
  reporting_registers_details_table_successful_message:
           'La version du micrologiciel a été mise à jour en',
  reporting_registers_details_table_no_record_found:
           'Aucun enregistrement trouvé',
  reporting_registers_details_table_row_per_page: 'Lignes par page',
  reporting_registers_details_bulk_export_file_name:
           'Détails du rapport groupé des registres',
  devices_bulk_edit_ota_form_firmware_field_select_label:
           'Sélectionnez le micrologiciel',
  dashboard_users_overview: 'Aperçu des utilisateurs',
  dashboard_users_title: 'Utilisateurs',
  dashboard_devices_overview: 'Aperçu des appareils',
  dashboard_devices_title: 'Appareils',
  dashboard_commissioning_overview: 'Aperçu de la mise en service',
  dashboard_commissioning_title: 'Mise en service',
  devices_filters: 'Filtres :',
  devices_submit_button: 'Soumettre',
  device_single_device_documentation_tab: 'Docs',
  device_single_documentation_brochure_button: 'Brochure',
  device_single_documentation_installation_manual_button:
           "Manuel d'installation",
  device_single_documentation_dimensional_drawing_button:
           'Dessin dimensionnel',
  device_single_documentation_parts_drawing_button: 'Dessin des pièces',
  device_single_documentation_flow_direction_button: 'Direction du flux',
  device_single_documentation_principle_diagram_button:
           'Schéma de principe',
  device_single_documentation_information_end_user_button:
           "Information à l'utilisateur final",
  device_single_documentation_malfunction_key_button:
           'Clé de dysfonctionnement',
  reporting_ota_failed_message:
           "La version du micrologiciel n'a pas été mise à jour en",
  reporting_ota_pending_message:
           'Mise à jour de la version du micrologiciel en cours en',
  reporting_ota_successful_message:
           'La version du micrologiciel a été mise à jour en',
  ota_pending: 'En attente',
  ota_success: 'Succès',
  ota_failed: 'Échec',
  clear_devices_modal_title: "Effacer l'appareil",
  clear_devices_modal_subtitle:
           'Êtes-vous sûr de vouloir effacer cet appareil avec le numéro de série <strong>##serial_number##</strong> ?',
  clear_devices_modal_cancel_button: 'Annuler',
  clear_devices_modal_submit_button: "Effacer l'appareil",
  sidebar_fortes_roles: 'Rôles',
  sidebar_fortes_permissions: 'Permissions',
  roles_all: 'Tous',
  roles_active: 'Actif',
  roles_inactive: 'Inactif',
  roles_filter_delete_button: 'Supprimer',
  roles_table_identifier: 'Identifiant',
  roles_table_name_en: 'Nom (EN)',
  roles_table_name_nl: 'Nom (NL)',
  roles_table_description_en: 'Description (EN)',
  roles_table_description_nl: 'Description (NL)',
  roles_table_status: 'Statut',
  roles_table_updated_at: 'Mis à jour le',
  roles_filter_search: 'Recherche',
  roles_no_record_found: 'Aucun enregistrement trouvé',
  roles_table_row_per_page: 'Lignes par page',
  roles_bulk_no_record_is_selected_error_message:
           'Veuillez sélectionner des enregistrements pour la désactivation groupée.',
  roles_deactivated_message: 'Le rôle a été désactivé avec succès',
  roles_activated_message: 'Le rôle a été activé avec succès',
  roles_bulk_deactivated_message:
           '##ROLES_COUNT## rôles ont été désactivés avec succès',
  roles_title: 'Rôles',
  roles_add_new: 'Ajouter un nouveau',
  roles_bread_crumb_main_title: 'Rôles',
  add_roles_bread_crumb_first_link: 'Ajouter un rôle',
  edit_roles_bread_crumb_first_link: 'Modifier le rôle',
  add_roles_form_header: 'Ajouter un rôle',
  edit_roles_form_header: 'Modifier le rôle',
  roles_form_role_identifier_field: 'Identifiant',
  roles_identifier_required_form_error: "L'identifiant est requis",
  roles_form_role_name_en_field: 'Nom (EN)',
  roles_form_role_name_nl_field: 'Nom (NL)',
  roles_name_en_required_form_error: 'Le nom (EN) est requis',
  roles_name_nl_required_form_error: 'Le nom (NL) est requis',
  roles_form_description_en_field: 'Description (EN)',
  roles_form_description_nl_field: 'Description (NL)',
  roles_form_api_error: 'Erreur interne du serveur',
  roles_form_unqiue_role_name_error: "L'identifiant doit être unique",
  roles_form_api_forbidden:
           "L'utilisateur n'a pas les autorisations pour créer un rôle",
  roles_add_form_success: 'Le rôle a été créé avec succès',
  roles_edit_form_success: 'Le rôle a été mis à jour avec succès',
  roles_form_add_role_button: 'Ajouter un rôle',
  roles_form_edit_role_button: 'Modifier le rôle',
  roles_form_cancel_button: 'Annuler',
  roles_form_permissions_title: 'Permissions',
  sidebar_fortes_modules: 'Modules',
  modules_all: 'Tous',
  modules_active: 'Actif',
  modules_inactive: 'Inactif',
  modules_filter_delete_button: 'Supprimer',
  modules_table_identifier: 'Identifiant',
  modules_table_name_en: 'Nom (EN)',
  modules_table_name_nl: 'Nom (NL)',
  modules_table_description_en: 'Description (EN)',
  modules_table_description_nl: 'Description (NL)',
  modules_table_status: 'Statut',
  modules_table_updated_at: 'Mis à jour le',
  modules_filter_search: 'Recherche',
  modules_no_record_found: 'Aucun enregistrement trouvé',
  modules_table_row_per_page: 'Lignes par page',
  modules_bulk_no_record_is_selected_error_message:
           'Veuillez sélectionner des enregistrements pour la désactivation en masse.',
  modules_deactivated_message: 'Le module a été désactivé avec succès',
  modules_activated_message: 'Le module a été activé avec succès',
  modules_bulk_deactivated_message:
           '##MODULES_COUNT## modules ont été désactivés avec succès',
  modules_title: 'Modules',
  modules_add_new: 'Ajouter un nouveau',
  modules_bread_crumb_main_title: 'Modules',
  add_modules_bread_crumb_first_link: 'Ajouter un module',
  edit_modules_bread_crumb_first_link: 'Modifier le module',
  add_modules_form_header: 'Ajouter un module',
  edit_modules_form_header: 'Modifier le module',
  modules_form_module_identifier_field: 'Identifiant',
  modules_identifier_required_form_error: "L'identifiant est requis",
  modules_form_module_name_en_field: 'Nom (EN)',
  modules_form_module_name_nl_field: 'Nom (NL)',
  modules_name_en_required_form_error: 'Le nom (EN) est requis',
  modules_name_nl_required_form_error: 'Le nom (NL) est requis',
  modules_form_description_en_field: 'Description (EN)',
  modules_form_description_nl_field: 'Description (NL)',
  modules_form_api_error: 'Erreur interne du serveur',
  modules_form_unqiue_module_name_error:
           "L'identifiant doit être unique",
  modules_form_api_forbidden:
           "L'utilisateur n'a pas les autorisations pour créer un module",
  modules_add_form_success: 'Le module a été créé avec succès',
  modules_edit_form_success: 'Le module a été mis à jour avec succès',
  modules_form_add_module_button: 'Ajouter un module',
  modules_form_edit_module_button: 'Modifier le module',
  modules_form_cancel_button: 'Annuler',
  permissions_all: 'Tous',
  permissions_active: 'Actif',
  permissions_inactive: 'Inactif',
  permissions_filter_delete_button: 'Supprimer',
  permissions_table_identifier: 'Identifiant',
  permissions_table_module_name: 'Module',
  permissions_table_name_en: 'Nom (EN)',
  permissions_table_name_nl: 'Nom (NL)',
  permissions_table_description_en: 'Description (EN)',
  permissions_table_description_nl: 'Description (NL)',
  permissions_table_status: 'Statut',
  permissions_filter_search: 'Recherche',
  permissions_no_record_found: 'Aucun enregistrement trouvé',
  permissions_table_row_per_page: 'Lignes par page',
  permissions_bulk_no_record_is_selected_error_message:
           'Veuillez sélectionner des enregistrements pour la désactivation en masse.',
  permissions_deactivated_message:
           'La permission a été désactivée avec succès',
  permissions_activated_message:
           'La permission a été activée avec succès',
  permissions_bulk_deactivated_message:
           '##PERMISSIONS_COUNT## permissions ont été désactivées avec succès',
  permissions_title: 'Permissions',
  permissions_add_new: 'Ajouter une nouvelle',
  permissions_bread_crumb_main_title: 'Permissions',
  add_permissions_bread_crumb_first_link: 'Ajouter une permission',
  edit_permissions_bread_crumb_first_link: 'Modifier la permission',
  edit_permissions_form_header: 'Modifier la permission',
  add_permissions_form_header: 'Ajouter une permission',
  permissions_form_permission_identifier_field: 'Identifiant',
  permissions_form_permission_module_field: 'Module',
  permissions_form_permission_select_module_field:
           'Sélectionner un module',
  permissions_module_required_form_error: 'Le module est requis',
  permissions_identifier_required_form_error: "L'identifiant est requis",
  permissions_form_permission_name_en_field: 'Nom (EN)',
  permissions_form_permission_name_nl_field: 'Nom (NL)',
  permissions_form_permission_sub_module_field: 'Sous-module',
  permissions_form_permission_select_sub_module_field:
           'Sélectionner un sous-module',
  permissions_sub_module_required_form_error:
           'Le sous-module est requis',
  permissions_name_required_form_error: 'Le nom est requis',
  permissions_form_description_en_field: 'Description (EN)',
  permissions_form_description_nl_field: 'Description (NL)',
  permissions_form_api_error: 'Erreur interne du serveur',
  permissions_form_unqiue_permission_name_error:
           "L'identifiant doit être unique",
  permissions_edit_form_success:
           'La permission a été mise à jour avec succès!',
  permissions_form_api_forbidden:
           "L'utilisateur n'a pas les autorisations pour créer une permission",
  permissions_add_form_success: 'La permission a été créée avec succès',
  permissions_form_add_permission_button: 'Ajouter une permission',
  permissions_form_edit_permission_button: 'Modifier la permission',
  permissions_form_cancel_button: 'Annuler',
  permissions_read: 'Lecture',
  permissions_create: 'Créer',
  permissions_update: 'Mettre à jour',
  permissions_delete: 'Supprimer',
  permissions_status: 'Basculer le statut',
  permissions_get_all: 'Tout obtenir',
  permissions_get_by_id: 'Obtenir par ID',
  permissions_bulk_deactivate: 'Désactiver en masse',
  permissions_form_title: 'Permissions',
  duplicate_roles_form_header: 'Dupliquer un rôle',
  duplicate_roles_bread_crumb_first_link: 'Dupliquer un rôle',
  roles_duplicate_form_success: 'Le rôle a été dupliqué avec succès',
  roles_filter_duplicate_button: 'Dupliquer un rôle',
  roles_pre_identifier_required_form_error:
           'Le pré-identifiant est requis',
  roles_form_pre_identifier_field: 'Pré-identifiant',
  roles_form_pre_identifier_select: 'Sélectionner un pré-identifiant',
  duplicate_roles_identifier_message:
           '<ul style="margin: "0", padding-left: 15px"> <li>Pour un nouveau rôle client, commencez toujours l\'identifiant par client_</li> <li>Pour un nouveau rôle de sous-client, commencez toujours l\'identifiant par service_client_</li> <li>Pour un nouveau rôle Fortes, commencez toujours l\'identifiant par fortes_</li> </ul>',
  devices_single_device_counters_title: 'Compteurs du dispositif',
  devices_single_device_counters_offline_counter: 'Compteur hors ligne',
  devices_single_device_counters_online_counter: 'Compteur en ligne',
  devices_single_device_counters_ota_fail_counter:
           "Compteur d'échec OTA",
  devices_single_device_counters_ota_update_counter:
           'Compteur de mises à jour OTA',
  devices_single_device_counters_mbus_error_counter:
           "Compteur d'erreurs MBus",
  devices_single_device_counters_modbus_error_counter:
           "Compteur d'erreurs Modbus",
  devices_single_device_docs_directory: 'Répertoires',
  device_single_documentation_no_documents_found:
           'Aucun document trouvé',
  reporting_registers_details_table_registers_pending:
           'Registres en attente',
  reporting_registers_details_table_registers_updated_fail:
           'Registres mis à jour en échec',
  reporting_registers_details_table_registers_updated_successful:
           'Registres mis à jour avec succès',
  reporting_registers_details_table_status: 'Statut',
  reporting_ota_message: 'Mettre à jour la version du micrologiciel à',
  devices_bulk_edit_registers_form_fix_register_values_error:
           'Veuillez corriger les erreurs dans toutes les onglets',
  login_permission_error:
           "Veuillez obtenir les permissions appropriées auprès de l'administrateur de Fortes",
  monitoring_title: 'Surveillance',
  monitoring_vm_title: 'Machine virtuelle',
  monitoring_ram_title: 'RAM',
  monitoring_cpu_title: 'UC',
  monitoring_disk_title: 'Disque',
  monitoring_restarts_title: 'Redémarrages',
  monitoring_uptime_title: 'Temps de fonctionnement',
  monitoring_services_title: 'Services',
  monitoring_service_table_service_name: 'Nom',
  monitoring_service_table_service_status: 'Statut',
  devices_form_service_client_field: 'Client de service',
  sidebar_3scale_users: 'Utilisateurs du client 3Scale',
  three_scale_users_title: 'Utilisateurs du client 3Scale',
  three_scale_users_table_name: 'Nom',
  three_scale_users_table_email: 'Email',
  three_scale_users_table_client: 'Client',
  three_scale_users_table_updated_at: 'Mis à jour le',
  three_scale_users_bread_crumb_main_title:
           'Utilisateurs du client 3Scale',
  add_three_scale_users_bread_crumb_first_link:
           'Ajouter un utilisateur du client 3Scale',
  add_three_scale_users_form_header:
           'Ajouter un utilisateur du client 3Scale',
  three_scale_users_form_name_field: 'Nom',
  three_scale_users_form_email_field: 'Adresse e-mail',
  three_scale_users_form_client_field: 'Client',
  three_scale_users_form_password_field: 'Mot de passe',
  three_scale_users_name_required_form_error: 'Le nom est requis',
  three_scale_users_email_valid_form_error:
           'Doit être une adresse e-mail valide',
  three_scale_users_email_required_form_error: "L'e-mail est requis",
  three_scale_users_client_required_form_error: 'Le client est requis',
  three_scale_users_password_required_form_error:
           'Le mot de passe doit contenir au moins 8 caractères avec au moins une lettre majuscule, au moins un chiffre et au moins un caractère spécial.',
  three_scale_users_no_record_found: 'Aucun enregistrement trouvé',
  three_scale_users_table_row_per_page: 'Lignes par page',
  three_scale_users_add_new: 'Ajouter un utilisateur du client 3Scale',
  three_scale_users_filter_search: 'Rechercher',
  three_scale_user_single_first_bread_crumb:
           'Utilisateurs du client 3Scale',
  three_scale_user_single_second_bread_crumb:
           'Voir les utilisateurs du client 3Scale',
  three_scale_user_single_apis_tab: 'Clés API',
  three_scale_user_single_edit_button_label: 'Modifier',
  three_scale_user_single_user_label: 'Utilisateur',
  three_scale_user_single_email_label: 'Email',
  three_scale_user_single_client_label: 'Client',
  edit_three_scale_users_form_header:
           'Modifier un utilisateur du client 3Scale',
  edit_three_scale_users_bread_crumb_first_link:
           'Modifier un utilisateur du client 3Scale',
  device_single_temperature_start_date: 'Date de début',
  device_single_temperature_end_date: 'Date de fin',
  device_single_temperature_start_date_error:
           'La date de début doit être inférieure ou égale à la date de fin',
  sidebar_3scale_admins: 'Administrateurs 3Scale',
  three_scale_user_admins_title: 'Administrateurs 3Scale',
  three_scale_user_admins_table_name: 'Nom',
  three_scale_user_admins_table_email: 'Email',
  three_scale_user_admins_table_updated_at: 'Mis à jour le',
  three_scale_user_admins_bread_crumb_main_title:
           'Administrateurs 3Scale',
  add_three_scale_user_admins_bread_crumb_first_link:
           'Ajouter un administrateur 3Scale',
  add_three_scale_user_admins_form_header:
           'Ajouter un administrateur 3Scale',
  three_scale_user_admins_form_name_field: 'Nom',
  three_scale_user_admins_form_email_field: 'Adresse e-mail',
  three_scale_user_admins_form_client_field: 'Administrateur',
  three_scale_user_admins_form_password_field: 'Mot de passe',
  three_scale_user_admins_name_required_form_error: 'Le nom est requis',
  three_scale_user_admins_email_valid_form_error:
           'Doit être une adresse e-mail valide',
  three_scale_user_admins_email_required_form_error:
           "L'e-mail est requis",
  three_scale_user_admins_password_required_form_error:
           'Le mot de passe doit contenir au moins 8 caractères avec au moins une lettre majuscule, au moins un chiffre et au moins un caractère spécial.',
  three_scale_user_admins_no_record_found: 'Aucun enregistrement trouvé',
  three_scale_user_admins_table_row_per_page: 'Lignes par page',
  three_scale_user_admins_add_new: 'Ajouter un administrateur 3Scale',
  three_scale_user_admins_filter_search: 'Rechercher',
  three_scale_user_admin_single_first_bread_crumb:
           'Administrateurs 3Scale',
  three_scale_user_admin_single_second_bread_crumb:
           'Voir les administrateurs 3Scale',
  three_scale_user_admin_single_apis_tab: 'Clés API',
  three_scale_user_admin_single_edit_button_label: 'Modifier',
  three_scale_user_admin_single_user_label: 'Utilisateur',
  three_scale_user_admin_single_email_label: 'E-mail',
  edit_three_scale_user_admins_form_header:
           "Modifier l'utilisateur administrateur 3Scale",
  edit_three_scale_user_admins_bread_crumb_first_link:
           "Modifier l'utilisateur administrateur 3Scale",
  projects_project_order_number_required_form_error:
           'Le numéro de commande du projet est requis',
  projects_form_project_order_number_field:
           'Numéro de commande du projet',
  client_error_single_device_address: "Adresse d'installation",
  navbar_notification_mark_all_as_read: 'Tout marquer comme lu',
  navbar_notification_view_all: 'Tout afficher',
  notifications_title: 'Notifications',
  notifications_all: 'Tout',
  notifications_unread: 'Non lu',
  notifications_read: 'Lu',
  notifications_table_title: 'Titre (EN)',
  notifications_table_description: 'Description (EN)',
  notifications_table_notification_type: 'Type de notification',
  notifications_table_notification_category: 'Catégorie de notification',
  notifications_table_date: 'Date',
  notifications_filter_search: 'Recherche',
  notifications_no_record_found: 'Aucun enregistrement trouvé',
  notifications_table_row_per_page: 'Lignes par page',
  add_notifications_form_header: 'Ajouter une notification',
  notifications_title_en_required_form_error: 'Le titre (EN) est requis',
  notifications_title_nl_required_form_error: 'Le titre (NL) est requis',
  notifications_description_en_required_form_error:
           'La description (EN) est requise',
  notifications_description_nl_required_form_error:
           'La description (NL) est requise',
  notifications_form_notification_type_field: 'Type de notification',
  notifications_notification_type_required_form_error:
           'Le type de notification est requis',
  notifications_form_notification_category_field:
           'Catégorie de notification',
  notifications_notification_category_required_form_error:
           'La catégorie de notification est requise',
  notifications_form_title_en_field: 'Titre (EN)',
  notifications_form_title_nl_field: 'Titre (NL)',
  notifications_form_start_date_field: 'Date de début',
  notifications_form_end_date_field: 'Date de fin',
  notifications_form_description_en_field: 'Description (EN)',
  notifications_form_description_nl_field: 'Description (NL)',
  notifications_form_add_button: 'Ajouter une notification',
  notifications_form_edit_button: 'Modifier la notification',
  notifications_form_cancel_button: 'Annuler',
  notifications_add_form_success:
           'La notification a été ajoutée avec succès',
  notifications_form_api_error: 'Erreur interne du serveur',
  notification_type_information: 'Information',
  notification_type_warning: 'Avertissement',
  notification_category_simple: 'Simple',
  notification_category_timebased: 'Basé sur le temps',
  edit_notifications_form_header: 'Modifier la notification',
  sidebar_notification_menu: 'Notifications',
  pop_over_close_button: 'Fermer',
  docs_user_type_end_user: 'Utilisateur final',
  docs_user_type_installer: 'Installateur',
  devices_single_device_docs_no_directory_found:
           'Aucun répertoire trouvé',
  device_single_status_tab: 'Visualisations',
  device_single_status_picture_tab: 'Image',
  device_single_status_principle_tab: 'Principe',
  device_single_status_table_loading: 'Chargement en cours...',
  device_single_status_mbus_export_button: 'Exporter',
  device_single_status_mbus_date_range_filter_label: 'Plage de dates',
  device_single_status_mbus_date_export_label: 'Date',
  device_single_status_mbus_time_export_label: 'Heure',
  device_single_status_mbus_port_export_label: 'Port',
  device_single_status_mbus_meter_connected_at_export_label:
           'Compteur connecté à',
  device_single_status_mbus_meter_status_export_label:
           'Statut du compteur',
  device_single_status_mbus_meter_type_export_label: 'Type de compteur',
  device_single_status_mbus_select_meter_type_export_label:
           'Sélectionner le type de compteur',
  device_single_status_mbus_select_meter_type_error:
           'Le type de compteur est manquant',
  device_single_status_mbus_export_internal_server_error:
           "Erreur interne du serveur lors de l'exportation",
  device_single_status_mbus_export_no_data_found_error:
           'Aucun enregistrement trouvé',
  sidebar_diagnostic_formulas: 'Formules de Diagnostic',
  diagnostic_formulas_all: 'Tout',
  diagnostic_formulas_active: 'Actif',
  diagnostic_formulas_inactive: 'Inactif',
  diagnostic_formulas_filter_delete_button: 'Supprimer',
  diagnostic_formulas_table_identifier: 'Identifiant',
  diagnostic_formulas_table_name_en: 'Nom (EN)',
  diagnostic_formulas_table_name_nl: 'Nom (NL)',
  diagnostic_formulas_table_description_en: 'Description (EN)',
  diagnostic_formulas_table_description_nl: 'Description (NL)',
  diagnostic_formulas_table_formula: 'Formule',
  diagnostic_formulas_table_status: 'Statut',
  diagnostic_formulas_table_updated_at: 'Mis à jour le',
  diagnostic_formulas_filter_search: 'Recherche',
  diagnostic_formulas_no_record_found: 'Aucun enregistrement trouvé',
  diagnostic_formulas_table_row_per_page: 'Lignes par page',
  diagnostic_formulas_bulk_no_record_is_selected_error_message:
           'Veuillez sélectionner des enregistrements pour la désactivation en masse.',
  diagnostic_formulas_deactivated_message:
           'Le diagnostic est désactivé avec succès',
  diagnostic_formulas_activated_message:
           'Le diagnostic est activé avec succès',
  diagnostic_formulas_bulk_deactivated_message:
           '##DIAGNOSTICS_COUNT## diagnostics sont désactivés avec succès',
  diagnostic_formulas_title: 'Formules de Diagnostics',
  diagnostic_formulas_add_new: 'Ajouter Nouveau',
  diagnostic_formulas_bread_crumb_main_title: 'Formules de Diagnostic',
  add_diagnostic_formulas_bread_crumb_first_link:
           'Ajouter des Formules de Diagnostic',
  edit_diagnostic_formulas_bread_crumb_first_link:
           'Modifier des Formules de Diagnostic',
  add_diagnostic_formulas_form_header:
           'Ajouter des Formules de Diagnostic',
  edit_diagnostic_formulas_form_header:
           'Modifier des Formules de Diagnostic',
  diagnostic_formulas_form_diagnostic_formulas_identifier_field:
           'Identifiant',
  diagnostic_formulas_identifier_required_form_error:
           "L'identifiant est requis",
  diagnostic_formulas_form_diagnostic_formulas_name_en_field: 'Nom (EN)',
  diagnostic_formulas_form_diagnostic_formulas_name_nl_field: 'Nom (NL)',
  diagnostic_formulas_name_en_required_form_error:
           'Le nom (EN) est requis',
  diagnostic_formulas_name_nl_required_form_error:
           'Le nom (NL) est requis',
  diagnostic_formulas_formula_required_form_error:
           'La formule est requise',
  diagnostic_formulas_form_description_en_field: 'Description (EN)',
  diagnostic_formulas_form_description_nl_field: 'Description (NL)',
  diagnostic_formulas_form_forumla_field: 'Formule',
  diagnostic_formulas_form_api_error: 'Erreur interne du serveur',
  diagnostic_formulas_form_unqiue_diagnostic_formulas_name_error:
           "L'identifiant doit être unique",
  diagnostic_formulas_form_api_forbidden:
           "L'utilisateur n'a pas les autorisations nécessaires pour créer des formules de diagnostic",
  diagnostic_formulas_add_form_success:
           'Le diagnostic est créé avec succès',
  diagnostic_formulas_edit_form_success:
           'Le diagnostic est mis à jour avec succès',
  diagnostic_formulas_form_add_diagnostic_formulas_button:
           'Ajouter le Diagnostic',
  diagnostic_formulas_form_edit_diagnostic_formulas_button:
           'Modifier le Diagnostic',
  diagnostic_formulas_form_cancel_button: 'Annuler',
  sidebar_diagnostic_categories: 'Catégories de Diagnostic',
  diagnostic_categories_all: 'Tout',
  diagnostic_categories_active: 'Actif',
  diagnostic_categories_inactive: 'Inactif',
  diagnostic_categories_filter_delete_button: 'Supprimer',
  diagnostic_categories_table_identifier: 'Identifiant',
  diagnostic_categories_table_name_en: 'Nom (EN)',
  diagnostic_categories_table_name_nl: 'Nom (NL)',
  diagnostic_categories_table_description_en: 'Description (EN)',
  diagnostic_categories_table_description_nl: 'Description (NL)',
  diagnostic_categories_table_status: 'Statut',
  diagnostic_categories_table_updated_at: 'Mis à jour le',
  diagnostic_categories_filter_search: 'Recherche',
  diagnostic_categories_no_record_found: 'Aucun enregistrement trouvé',
  diagnostic_categories_table_row_per_page: 'Lignes par page',
  diagnostic_categories_bulk_no_record_is_selected_error_message:
           'Veuillez sélectionner des enregistrements pour la désactivation en masse.',
  diagnostic_categories_deactivated_message:
           'La catégorie est désactivée avec succès',
  diagnostic_categories_activated_message:
           'La catégorie est activée avec succès',
  diagnostic_categories_bulk_deactivated_message:
           '##DIAGNOSTICS_COUNT## catégories sont désactivées avec succès',
  diagnostic_categories_title: 'Catégories de Diagnostic',
  diagnostic_categories_add_new: 'Ajouter Nouveau',
  diagnostic_categories_bread_crumb_main_title:
           'Catégories de Diagnostic',
  add_diagnostic_categories_bread_crumb_first_link:
           'Ajouter une Catégorie de Diagnostic',
  edit_diagnostic_categories_bread_crumb_first_link:
           'Modifier une Catégorie de Diagnostic',
  add_diagnostic_categories_form_header:
           'Ajouter une Catégorie de Diagnostic',
  edit_diagnostic_categories_form_header:
           'Modifier une Catégorie de Diagnostic',
  diagnostic_categories_form_diagnostic_categories_identifier_field:
           'Identifiant',
  diagnostic_categories_identifier_required_form_error:
           "L'identifiant est requis",
  diagnostic_categories_form_diagnostic_categories_name_en_field:
           'Nom (EN)',
  diagnostic_categories_form_diagnostic_categories_name_nl_field:
           'Nom (NL)',
  diagnostic_categories_name_en_required_form_error:
           'Le nom (EN) est requis',
  diagnostic_categories_name_nl_required_form_error:
           'Le nom (NL) est requis',
  diagnostic_categories_form_description_en_field: 'Description (EN)',
  diagnostic_categories_form_description_nl_field: 'Description (NL)',
  diagnostic_categories_form_api_error: 'Erreur interne du serveur',
  diagnostic_categories_form_unqiue_diagnostic_categories_name_error:
           "L'identifiant doit être unique",
  diagnostic_categories_form_api_forbidden:
           "L'utilisateur n'a pas les autorisations nécessaires pour créer des catégories de diagnostic",
  diagnostic_categories_add_form_success:
           'La catégorie est créée avec succès',
  diagnostic_categories_edit_form_success:
           'La catégorie est mise à jour avec succès',
  diagnostic_categories_form_add_diagnostic_categories_button:
           'Ajouter une Catégorie',
  diagnostic_categories_form_edit_diagnostic_categories_button:
           'Modifier une Catégorie',
  diagnostic_categories_form_cancel_button: 'Annuler',
  sidebar_diagnostics_overview: 'Diagnostics',
  sidebar_firmware_menu: 'Firmwares',
  firmwares_all: 'Tout',
  firmwares_active: 'Actif',
  firmwares_inactive: 'Inactif',
  firmwares_table_identifier: 'Identifiant',
  firmwares_table_name_en: 'Nom (EN)',
  firmwares_table_name_nl: 'Nom (NL)',
  firmwares_table_firmware: 'Micrologiciel',
  firmwares_table_status: 'Statut',
  firmwares_table_updated_at: 'Mis à jour le',
  firmwares_filter_search: 'Recherche',
  firmwares_no_record_found: 'Aucun enregistrement trouvé',
  firmwares_table_row_per_page: 'Lignes par page',
  firmwares_bulk_no_record_is_selected_error_message:
           'Veuillez sélectionner des enregistrements pour la désactivation en masse.',
  firmwares_deactivated_message:
           'Le micrologiciel est désactivé avec succès',
  firmwares_activated_message: 'Le micrologiciel est activé avec succès',
  firmwares_title: 'Micrologiciels',
  firmwares_add_new: 'Ajouter Nouveau',
  firmwares_bread_crumb_main_title: 'Micrologiciels',
  add_firmwares_bread_crumb_first_link: 'Ajouter un Micrologiciel',
  edit_firmwares_bread_crumb_first_link: 'Modifier un Micrologiciel',
  add_firmwares_form_header: 'Ajouter un Micrologiciel',
  edit_firmwares_form_header: 'Modifier un Micrologiciel',
  firmwares_form_firmware_identifier_field: 'Identifiant',
  firmwares_identifier_required_form_error: "L'identifiant est requis",
  firmwares_form_firmware_name_en_field: 'Nom (EN)',
  firmwares_form_firmware_name_nl_field: 'Nom (NL)',
  firmwares_form_firmware_firmware_field: 'Micrologiciel',
  firmwares_name_en_required_form_error: 'Le nom (EN) est requis',
  firmwares_name_nl_required_form_error: 'Le nom (NL) est requis',
  firmwares_form_description_en_field: 'Description (EN)',
  firmwares_form_description_nl_field: 'Description (NL)',
  firmwares_form_api_error: 'Erreur interne du serveur',
  firmwares_form_unqiue_firmware_name_error:
           "L'identifiant doit être unique",
  firmwares_form_api_forbidden:
           "L'utilisateur n'a pas les autorisations nécessaires pour créer un micrologiciel",
  firmwares_add_form_success: 'Le micrologiciel est créé avec succès',
  firmwares_edit_form_success:
           'Le micrologiciel est mis à jour avec succès',
  firmwares_form_edit_firmware_button: 'Modifier le Micrologiciel',
  firmwares_form_cancel_button: 'Annuler',
  diagnostic_flows_all: 'Tout',
  diagnostic_flows_active: 'Actif',
  diagnostic_flows_inactive: 'Inactif',
  diagnostic_flows_filter_delete_button: 'Supprimer',
  diagnostic_flows_table_identifier: 'Identifiant',
  diagnostic_flows_table_category: 'Catégorie',
  diagnostic_flows_table_name_en: 'Nom (EN)',
  diagnostic_flows_table_name_nl: 'Nom (NL)',
  diagnostic_flows_table_description_en: 'Description (EN)',
  diagnostic_flows_table_description_nl: 'Description (NL)',
  diagnostic_flows_table_status: 'Statut',
  diagnostic_flows_table_updated_at: 'Mis à jour le',
  diagnostic_flows_filter_search: 'Recherche',
  diagnostic_flows_no_record_found: 'Aucun enregistrement trouvé',
  diagnostic_flows_table_row_per_page: 'Lignes par page',
  diagnostic_flows_bulk_no_record_is_selected_error_message:
           'Veuillez sélectionner des enregistrements pour la désactivation en masse.',
  diagnostic_flows_deactivated_message:
           'Le flux est désactivé avec succès',
  diagnostic_flows_activated_message: 'Le flux est activé avec succès',
  diagnostic_flows_bulk_deactivated_message:
           '##DIAGNOSTICS_COUNT## flux sont désactivés avec succès',
  diagnostic_flows_title: 'Flux de Diagnostic',
  diagnostic_flows_add_new: 'Ajouter Nouveau',
  diagnostic_flows_bread_crumb_main_title: 'Flux de Diagnostic',
  add_diagnostic_flows_bread_crumb_first_link:
           'Ajouter un Flux de Diagnostic',
  edit_diagnostic_flows_bread_crumb_first_link:
           'Modifier un Flux de Diagnostic',
  add_diagnostic_flows_form_header: 'Ajouter un Flux de Diagnostic',
  edit_diagnostic_flows_form_header: 'Modifier un Flux de Diagnostic',
  diagnostic_flows_form_diagnostic_flows_identifier_field: 'Identifiant',
  diagnostic_flows_identifier_required_form_error:
           "L'identifiant est requis",
  diagnostic_flows_form_diagnostic_flows_name_en_field: 'Nom (EN)',
  diagnostic_flows_form_diagnostic_flows_name_nl_field: 'Nom (NL)',
  diagnostic_flows_name_en_required_form_error: 'Le nom (EN) est requis',
  diagnostic_flows_name_nl_required_form_error: 'Le nom (NL) est requis',
  diagnostic_flows_category_required_form_error:
           'La catégorie est requise',
  diagnostic_flows_form_description_en_field: 'Description (EN)',
  diagnostic_flows_form_description_nl_field: 'Description (NL)',
  diagnostic_flows_form_flow_condition_error_field:
           'La condition doit avoir des règles vraies et fausses',
  diagnostic_flows_form_flow_modal_title_field: 'Êtes-vous sûr ?',
  diagnostic_flows_form_flow_modal_subtitle_field:
           'Vous voulez enregistrer les modifications du flux dans le navigateur ?',
  diagnostic_flows_form_flow_modal_cancel_button_field: 'Annuler',
  diagnostic_flows_form_flow_modal_save_button_field:
           'Enregistrer dans le navigateur',
  diagnostic_flows_form_flow_modal_save_success_message:
           'Le flux est enregistré avec succès dans le navigateur',
  diagnostic_flows_form_featured_image_field: 'Image en Vedette',
  diagnostic_flows_form_upload_featured_image_label:
           "Télécharger l'Image en Vedette",
  diagnostic_flows_form_thumbnail_field: 'Miniature',
  diagnostic_flows_form_upload_thumbnail_image_label:
           "Télécharger l'Image Miniature",
  diagnostic_flows_form_api_error: 'Erreur interne du serveur',
  diagnostic_flows_form_unqiue_diagnostic_flows_name_error:
           "L'identifiant doit être unique",
  diagnostic_flows_form_api_forbidden:
           "L'utilisateur n'a pas les autorisations nécessaires pour créer des flux de diagnostic",
  diagnostic_flows_add_form_success:
           'Le Flux de Diagnostic est créé avec succès',
  diagnostic_flows_edit_form_success:
           'Le Flux de Diagnostic est mis à jour avec succès',
  diagnostic_flows_form_add_diagnostic_flows_button: 'Ajouter un Flux',
  diagnostic_flows_form_edit_diagnostic_flows_button: 'Modifier le Flux',
  diagnostic_flows_form_cancel_button: 'Annuler',
  audits_select_event_type_error_option: 'Erreur',
  diagnostic_flows_form_flow_field: 'Flux',
  diagnostic_flows_form_flow_condition_button: 'Condition',
  diagnostic_flows_form_flow_timer_button: 'Minuteur',
  diagnostic_flows_form_flow_normal_node_button: 'Noeud',
  diagnostic_flows_form_flow_download_image_button:
           "Télécharger l'Image",
  diagnostic_flows_form_flow_start_button: 'Démarrer',
  diagnostic_flows_form_flow_end_button: 'Terminer',
  devices_bulk_export_mbus_historic_data_button:
           'Télécharger les données Mbus',
  devices_bulk_export_mbus_historic_data_file_name:
           'Données Historiques Mbus',
  devices_bulk_mbus_download_title:
           'Télécharger les Données Historiques Mbus',
  devices_bulk_bulk_mbus_download_form_date_range: 'Plage de Dates :',
  devices_bulk_bulk_mbus_download_form_submit_button:
           'Télécharger les Données Mbus',
  devices_bulk_bulk_mbus_download_form_cancel_button: 'Annuler',
  devices_bulk_mbus_download_form_success:
           'Les Données Historiques Mbus sont envoyées par email à ##EMAIL## avec succès !',
  devices_bulk_mbus_download_form_api_error: 'Erreur interne du serveur',
  devices_bulk_mbus_download_no_devices_error: '',
  devices_bulk_mbus_download_form_no_devices_error:
           'Veuillez sélectionner des appareils pour continuer.',
  devices_bulk_bulk_mbus_download_modal_title: 'Êtes-vous sûr ?',
  devices_bulk_mbus_download_modal_subtitle:
           'Voulez-vous lancer un téléchargement en masse des données historiques Mbus ?',
  devices_bulk_mbus_download_modal_cancel_button: 'Annuler',
  devices_bulk_mbus_download_modal_submit_button: 'Confirmer',
  projects_bulk_export_mbus_historic_data_button:
           'Télécharger les données Mbus',
  projects_bulk_export_mbus_historic_data_file_name:
           'Données Historiques Mbus',
  projects_bulk_mbus_download_title:
           'Télécharger les Données Historiques Mbus',
  projects_bulk_bulk_mbus_download_form_date_range: 'Plage de Dates :',
  projects_bulk_bulk_mbus_download_form_data_export_type:
           "Type d'Export de Données",
  projects_bulk_bulk_mbus_download_form_data_export_type_combined:
           'Combiné',
  projects_bulk_bulk_mbus_download_form_data_export_type_daily:
           'Journalier',
  projects_bulk_bulk_mbus_download_form_data_export_type_normal:
           'Normal',
  projects_bulk_bulk_mbus_download_form_submit_button:
           'Télécharger les Données Mbus',
  projects_bulk_bulk_mbus_download_form_cancel_button: 'Annuler',
  projects_bulk_mbus_download_form_success:
           'Les Données Historiques Mbus sont envoyées par email à ##EMAIL## avec succès !',
  projects_bulk_mbus_download_form_api_error:
           'Erreur interne du serveur',
  projects_bulk_mbus_download_no_projects_error: '',
  projects_bulk_mbus_download_form_no_projects_error:
           'Veuillez sélectionner des projets pour continuer.',
  projects_bulk_bulk_mbus_download_modal_title: 'Êtes-vous sûr ?',
  projects_bulk_mbus_download_modal_subtitle:
           'Voulez-vous lancer un téléchargement en masse des données historiques Mbus ?',
  projects_bulk_mbus_download_modal_cancel_button: 'Annuler',
  projects_bulk_mbus_download_modal_submit_button: 'Confirmer',
  projects_bulk_mbus_selected_projects: 'Projets Sélectionnés',
  sidebar_diagnostic_flows: 'Flux de Diagnostic',
  diagnostic_flows_form_diagnostic_flows_category_field:
           'Catégorie de Flux de Diagnostic',
  diagnostics_send_message_modal_registers_label: 'Registres',
  diagnostics_send_message_modal_operator_label: 'Opérateur',
  diagnostics_send_message_modal_value_label: 'Valeur',
  diagnostics_send_message_modal_registers_label_default_option:
           'Sélectionner le Registre',
  diagnostics_send_message_modal_save_button: 'Enregistrer',
  diagnostics_send_message_modal_cancel_button: 'Annuler',
  diagnostics_condition_modal_sensor_type_label: 'Type de Capteur',
  diagnostics_condition_modal_modbus_label: 'Capteur Modbus',
  diagnostics_condition_modal_mbus_label: 'Capteur Mbus',
  diagnostics_condition_modal_formula_output_label:
           'Résultat de la Formule',
  diagnostics_condition_modal_meter_type_label: 'Type de Compteur',
  diagnostics_condition_modal_meter_type_label_default_option:
           'Sélectionner le Type de Compteur',
  diagnostics_condition_modal_operator_label: 'Opérateur',
  diagnostics_condition_modal_operator_option_1: 'Inférieur',
  diagnostics_condition_modal_operator_option_2: 'Inférieur ou égal à',
  diagnostics_condition_modal_operator_option_3: 'Égal à',
  diagnostics_condition_modal_operator_option_4: 'Supérieur',
  diagnostic_flows_menu_information_form_description_nl:
           'Description (NL)',
  diagnostic_flows_menu_information_form_cancel_button: 'Annuler',
  diagnostic_flows_menu_information_form_save_button: 'Enregistrer',
  diagnostic_flows_menu_condition_form_description_en:
           'Description (EN)',
  diagnostic_flows_menu_condition_form_description_nl:
           'Description (NL)',
  diagnostic_flows_menu_condition_form_register_label: 'Registre',
  diagnostic_flows_menu_condition_form_operator_label: 'Opérateur',
  diagnostic_flows_menu_condition_form_value_label: 'Valeur',
  diagnostic_flows_menu_condition_form_timer_label: 'Minuteur',
  diagnostic_flows_menu_condition_form_retries_label: 'Réessais',
  diagnostic_flows_menu_condition_form_delay_label: 'Délai',
  diagnostic_flows_menu_condition_form_times_label: 'fois',
  diagnostic_flows_menu_condition_form_second_label: 'sec',
  diagnostic_flows_menu_condition_form_cancel_button: 'Annuler',
  diagnostic_flows_menu_condition_form_save_button: 'Enregistrer',
  diagnostic_flows_menu_modbus_form_description_en: 'Description (EN)',
  diagnostic_flows_menu_modbus_form_description_nl: 'Description (NL)',
  diagnostic_flows_menu_modbus_form_register_label: 'Registre',
  diagnostic_flows_menu_modbus_form_operator_label: 'Opérateur',
  diagnostic_flows_menu_modbus_form_value_label: 'Valeur',
  diagnostic_flows_menu_modbus_form_cancel_button: 'Annuler',
  diagnostic_flows_menu_modbus_form_save_button: 'Enregistrer',
  diagnostic_flows_menu_message_form_description_en: 'Description (EN)',
  diagnostic_flows_menu_message_form_description_nl: 'Description (NL)',
  diagnostic_flows_menu_message_form_message_label: 'Message',
  diagnostic_flows_menu_message_form_cancel_button: 'Annuler',
  diagnostic_flows_menu_message_form_save_button: 'Enregistrer',
  diagnostic_flows_menu_timer_form_description_en: 'Description (EN)',
  diagnostic_flows_menu_timer_form_description_nl: 'Description (NL)',
  diagnostic_flows_menu_timer_form_timer_label: 'Minuteur',
  diagnostic_flows_menu_timer_form_delay_label: 'Délai',
  diagnostic_flows_menu_timer_form_times_label: 'fois',
  diagnostic_flows_menu_timer_form_second_label: 'sec',
  diagnostic_flows_menu_timer_form_cancel_button: 'Annuler',
  diagnostic_flows_menu_timer_form_save_button: 'Enregistrer',
  diagnostic_flows_menu_formula_form_description_en: 'Description (EN)',
  diagnostic_flows_menu_formula_form_description_nl: 'Description (NL)',
  diagnostic_flows_menu_formula_form_formula_label: 'Formule',
  diagnostic_flows_menu_formula_form_formula_placeholder:
           'Veuillez choisir @ pour sélectionner les Registres Modbus ou Mbus',
  diagnostic_flows_menu_formula_form_cancel_button: 'Annuler',
  diagnostic_flows_menu_formula_form_save_button: 'Enregistrer',
  diagnostic_flows_menu_user_interaction_form_description_en:
           'Description (EN)',
  diagnostic_flows_menu_user_interaction_form_description_nl:
           'Description (NL)',
  diagnostic_flows_menu_user_interaction_form_operation_type:
           "Type d'Opération",
  diagnostic_flows_menu_user_interaction_form_operation_type_default_option:
           "Sélectionner le Type d'Opération",
  diagnostic_flows_menu_user_interaction_form_operation_type_option_choose_mbus_meter:
           'Sélectionner le Compteur Mbus',
  diagnostic_flows_menu_user_interaction_form_operation_type_option_choose_faucet:
           'Sélectionner le Registre de Robinet',
  diagnostic_flows_menu_user_interaction_form_field_type_option_select_field:
           'Sélectionner le Type de Champ',
  diagnostic_flows_menu_user_interaction_form_field_type:
           'Type de Champ',
  diagnostic_flows_menu_user_interaction_form_field_type_default_option:
           'Sélectionner le Type de Champ',
  diagnostic_flows_menu_user_interaction_form_field_type_option_checkbox:
           'Case à cocher',
  diagnostic_flows_menu_user_interaction_form_field_type_option_radio_button:
           'Bouton Radio',
  diagnostic_flows_menu_user_interaction_form_field_type_option_text_field:
           'Champ de Texte',
  diagnostic_flows_menu_user_interaction_form_cancel_button: 'Annuler',
  diagnostic_flows_menu_user_interaction_form_save_button: 'Enregistrer',
  extra_white_space_form_error:
           'Les espaces supplémentaires ne sont pas autorisés',
  diagnostic_flows_form_incomplete_flow_error:
           'Veuillez vérifier les arêtes et les nœuds de couleur noire avec animation rouge',
  diagnostic_flows_menu_information: 'Information',
  diagnostic_flows_menu_modbus: 'Modbus',
  diagnostic_flows_menu_message: 'Message',
  diagnostic_flows_menu_condition: 'Condition',
  diagnostic_flows_menu_user_interaction: 'Interaction Utilisateur',
  diagnostic_flows_menu_steps: 'Page',
  diagnostic_flows_menu_timer: 'Minuteur',
  diagnostic_flows_menu_formula: 'Formule',
  diagnostic_flows_form_user_interaction_operation_type:
           "Type d'Opération",
  diagnostic_flows_form_user_interaction_operation_type_default_option:
           "Sélectionner le Type d'Opération",
  diagnostic_flows_form_user_interaction_operation_type_option_choose_mbus_meter:
           'Sélectionner le Compteur Mbus',
  diagnostic_flows_form_user_interaction_operation_type_option_choose_faucet:
           'Sélectionner le Registre de Robinet',
  diagnostic_flows_form_user_interaction_field_type_option_select_field:
           'Sélectionner le Type de Champ',
  diagnostic_flows_form_user_interaction_field_type: 'Type de Champ',
  diagnostic_flows_form_user_interaction_field_type_default_option:
           'Sélectionner le Type de Champ',
  diagnostic_flows_form_user_interaction_field_type_option_checkbox:
           'Case à cocher',
  diagnostic_flows_form_user_interaction_field_type_option_radio_button:
           'Bouton Radio',
  diagnostic_flows_form_user_interaction_field_type_option_text_field:
           'Champ de Texte',
  diagnostic_flows_form_user_interaction_description_en:
           'Description (EN)',
  diagnostic_flows_form_user_interaction_description_nl:
           'Description (NL)',
  diagnostic_flows_form_steps_label_en: 'Description (EN)',
  diagnostic_flows_form_steps_label_nl: 'Description (NL)',
  diagnostic_formulas_form_diagnostic_formulas_retries_field: 'Réessais',
  diagnostic_formulas_form_diagnostic_formulas_delay_field: 'Délai',
  diagnostic_formulas_form_diagnostic_formulas_unit_field:
           'Unité de Formule',
  diagnostic_formulas_form_forumla_field_placeholder:
           'Veuillez choisir @ pour sélectionner les Registres Modbus ou Mbus',
  device_single_diagnostic_flow_missing_pages_error:
           "##SELECTED_FLOW## n'a pas de pages. Veuillez ajouter des pages dans le flux pour continuer.",
  device_single_stepper_choose_flow_title: 'Choisissez le flux',
  device_single_stepper_choose_explanation_of_flow_wizard_title:
           "Explication de l'assistant",
  device_single_stepper_result_title: 'Résultat',
  devices_bulk_bulk_mbus_download_form_data_type: 'Type de Données',
  devices_bulk_bulk_mbus_download_form_export_type: "Type d'Exportation",
  devices_bulk_bulk_mbus_download_form_data_type_daily: 'Journalier',
  devices_bulk_bulk_mbus_download_form_data_type_hourly: 'Horaire',
  devices_bulk_bulk_mbus_download_form_export_type_combined: 'Combiné',
  devices_bulk_bulk_mbus_download_form_export_type_separated: 'Séparé',
  diagnostic_flows_form_main_stopped_text:
           "Le flux a été arrêté par l'utilisateur",
  diagnostic_execution_title: 'Exécutions de Diagnostic',
  diagnostic_execution_table_created_at: 'Créé le',
  diagnostic_execution_table_device: 'Appareil',
  diagnostic_execution_table_user: 'Utilisateur',
  diagnostic_execution_table_flow: 'Flux',
  diagnostic_execution_table_status: 'Statut',
  diagnostic_execution_table_filter_search: 'Recherche',
  diagnostic_executions_tab_all: 'Tous',
  diagnostic_executions_tab_completed: 'Terminé',
  diagnostic_executions_tab_in_progress: 'En cours',
  diagnostic_executions_tab_stopped: 'Arrêté',
  diagnostic_executions_tab_error: 'Erreur',
  diagnostic_executions_table_no_record_found:
           'Aucun enregistrement trouvé',
  diagnostic_executions_table_row_per_page: 'Lignes par page',
  diagnostic_flows_menu_result: 'Résultat',
  diagnostic_flows_menu_result_form_description_en: 'Description (EN)',
  diagnostic_flows_menu_result_form_description_nl: 'Description (NL)',
  diagnostic_flows_menu_result_form_cancel_button: 'Annuler',
  diagnostic_flows_menu_result_form_save_button: 'Enregistrer',
  diagnostic_flows_menu_page_form_cancel_button: 'Annuler',
  diagnostic_flows_menu_page_form_save_button: 'Enregistrer',
  diagnostic_flows_menu_information_form_description_en:
           'Description (EN)',
  sidebar_diagnostic_execution_title: 'Exécutions de Diagnostic',
  diagnostic_flow_execution_single_first_bread_crumb:
           'Exécutions de Diagnostic',
  diagnostic_flow_execution_single_second_bread_crumb:
           'Voir Exécution de Diagnostic',
  diagnostic_flow_execution_single_output_tab: 'Résultat',
  diagnostic_flow_execution_single_device_name: 'Appareil',
  diagnostic_flow_execution_single_flow: 'Flux',
  diagnostic_flow_execution_single_user: 'Utilisateur',
  diagnostic_flow_execution_single_status: 'Statut',
  diagnostic_flow_execution_single_created_at: 'Créé le',
  diagnostic_flow_execution_single_bulk_export_file_name:
           'Exécutions de Diagnostic en Bloc',
  diagnostic_flow_execution_single_bulk_export_date: 'Date',
  diagnostic_flow_execution_single_bulk_export_time: 'Heure',
  diagnostic_flow_execution_single_bulk_export_device_name:
           "Nom de l'Appareil",
  diagnostic_flow_execution_single_bulk_export_device_serial_number_mercurius:
           'Numéro de Série Mercurius',
  diagnostic_flow_execution_single_bulk_export_device_user_full_name:
           'Utilisateur',
  diagnostic_flow_execution_single_bulk_export_device_flow_name_en:
           'Nom du Flux (EN)',
  diagnostic_flow_execution_single_bulk_export_device_flow_name_nl:
           'Nom du Flux (NL)',
  diagnostic_flow_execution_single_bulk_export_device_status: 'Statut',
  diagnostic_flows_form_main_flow_already_running_error_text:
           "Un flux est déjà en cours d'exécution sur cet appareil. Les exécutions de flux multiples ne sont pas autorisées.",
  diagnostic_flows_form_open_faucet_on_option: 'Activé',
  diagnostic_flows_form_open_faucet_off_option:
           "Désactivé (Le flux passera en mode d'erreur)",
  diagnostic_flows_form_mbus_meters_error:
           "L'appareil ne possède pas les compteurs Mbus nécessaires au flux.",
  diagnostic_flows_result_title: 'Résultat',
  duplicate_diagnostic_flows_form_header:
           'Dupliquer le Flux de Diagnostic',
  duplicate_diagnostic_flows_bread_crumb_first_link:
           'Dupliquer le Flux de Diagnostic',
  diagnostic_flows_form_duplicate_diagnostic_flows_button: 'Dupliquer',
  diagnostic_flows_duplicate_form_success:
           'Le Flux de Diagnostic a été dupliqué avec succès',
  diagnostic_flows_filter_duplicate_button: 'Dupliquer le Flux',
  diagnostic_flows_menu_result_form_id: 'Id',
  audits_street_number_filter_label: 'Rue',
  audits_house_number_filter_label: 'Numéro de Maison',
  diagnostic_execution_counter_title:
           "Compteurs d'Exécution de Diagnostic",
  diagnostic_execution_counter_table_id: 'Id',
  diagnostic_execution_counter_table_counter: 'Compteurs',
  diagnostic_execution_counter_table_flow: 'Flux',
  diagnostic_execution_counter_table_result: 'Résultat',
  diagnostic_execution_counter_table_filter_search: 'Rechercher',
  diagnostic_execution_counter_table_no_record_found:
           'Aucun enregistrement trouvé',
  diagnostic_execution_counter_table_row_per_page: 'Lignes par Page',
  sidebar_diagnostic_flow_execution_counter:
           'Compteurs de Flux de Diagnostic',
  device_single_firmware_hiu_label: 'Micrologiciel MTA',
  device_single_firmware_mercurius_label: 'Micrologiciel Mercurius',
  custom_popover_105_long_description:
           "Description Longue du Registre d'Alarme",
  settings_menu_title: 'Paramètres',
  settings_menu_clients_title: 'Clients',
  settings_menu_admins_title: 'Administrateurs',
  settings_menu_3scale_admins_title: 'Administrateurs 3Scale',
  settings_menu_3scale_clients_title: 'Clients 3Scale',
  settings_menu_diagnostics_title: 'Diagnostics',
  settings_menu_formulas_title: 'Formules',
  settings_menu_flows_title: 'Flux',
  settings_menu_categories_title: 'Catégories',
  settings_menu_firmwares_title: 'Micrologiciels',
  settings_menu_appliance_types_title: "Types d'Appareil",
  settings_menu_notifications_title: 'Notifications',
  settings_menu_roles_title: 'Rôles',
  settings_menu_modules_title: 'Modules',
  settings_menu_permissions_title: 'Permissions',
  settings_menu_go_back_button: 'Revenir en Arrière',
  audits_device_name_filter_label: "Nom de l'Appareil",
  device_single_documentation_parts_button: 'Pièces',
  device_single_documentation_lab_vision_button: 'Labvision handleiding',
  device_single_documentation_manuals_button: 'Manuels',
  device_single_documentation_incident_button: "Clé d'Incident",
  navbar_avg_proof_switch: 'Interrupteur de Confidentialité',
  clients_filter_bulk_delete_button: 'Supprimer',
  clients_bulk_delete_no_clients_with_zero_devices_is_selected_error_message:
           'Veuillez sélectionner au moins un enregistrement avec 0 appareil',
  clients_bulk_delete_clients_with_devices_count_is_selected_error_message:
           'Veuillez sélectionner uniquement les enregistrements qui ont 0 appareil',
  projects_filter_bulk_delete_button: 'Supprimer',
  projects_bulk_delete_no_projects_with_zero_devices_is_selected_error_message:
           'Veuillez sélectionner au moins un enregistrement avec 0 appareil',
  projects_bulk_delete_projects_with_devices_count_is_selected_error_message:
           'Veuillez sélectionner uniquement les enregistrements qui ont 0 appareil',
  projects_bulk_deleted_message:
           '{{ PROJECTS_COUNT }} projets sont supprimés avec succès',
  projects_delete_modal_title: 'Êtes-vous sûr?',
  projects_delete_modal_subtitle:
           'Voulez-vous supprimer les enregistrements?',
  projects_delete_modal_cancel_button: 'Annuler',
  projects_delete_modal_delete_button: 'Supprimer',
  users_delete_modal_title: 'Êtes-vous sûr?',
  users_delete_modal_subtitle:
           'Voulez-vous supprimer les enregistrements?',
  users_delete_modal_cancel_button: 'Annuler',
  users_delete_modal_delete_button: 'Supprimer',
  users_bulk_delete_no_user_is_selected_error_message:
           'Veuillez sélectionner des enregistrements à supprimer',
  users_bulk_deleted_message:
           '{{ USERS_COUNT }} utilisateurs sont supprimés avec succès',
  service_client_single_filter_bulk_delete_button: 'Supprimer',
  service_client_single_no_user_selected_for_deleted_message:
           'Veuillez sélectionner des enregistrements à supprimer',
  admins_bulk_deleted_message:
           '{{ USERS_COUNT }} administrateurs sont supprimés avec succès',
  admins_no_user_selected_for_deleted_message:
           'Veuillez sélectionner des enregistrements à supprimer',
  admins_filter_bulk_delete_button: 'Supprimer',
  three_scale_admins_bulk_deleted_message:
           '{{ USERS_COUNT }} administrateurs ThreeScale sont supprimés avec succès',
  audits_message_type_label: 'Type de Message',
  audit_bulk_export_file_name: "Fichier d'Audits",
  registers_reporting_details_all_tab: 'Tous',
  registers_reporting_details_pending_tab: 'En Attente',
  registers_reporting_details_success_tab: 'Succès',
  registers_reporting_details_failure_tab: 'Échec',
  devices_form_is_service_device_field: 'Appareil de Service',
  service_portal_login_in_correct_qr_code:
           "Le Numéro de Série de l'Appareil est incorrect",
  service_portal_login_in_correct_credentials:
           "Les identifiants de l'appareil sont incorrects. Veuillez contacter l'administrateur Fortes.",
  service_portal_login_not_a_registered_service_device:
           "L'appareil avec le Numéro de Série {{ SERIAL_NUMBER }} n'est pas enregistré.",
  devices_unprovisioned: 'Non installé',
  sidebar_unprovisioned_devices: 'Appareils non installés',
  dashboard_unprovisioned: 'NON INSTALLÉ',
  device_single_documentation_warmwater_button: 'Erreur eau chaude',
  device_single_documentation_verwarming_button:
           'Erreur chauffage central',
  service_portal_login_in_serial_number_scan_button:
           'Scanner le Numéro de Série',
  service_portal_login_in_heat_interface_unit_scan_button:
           "Scanner l'Unité d'Interface Thermique",
  service_portal_login_in_serial_number_scanned_message:
           'Le Numéro de Série est scanné',
  service_portal_login_in_heat_interface_unit_scanned_message:
           "L'Unité d'Interface Thermique est scannée",
  swapping_device_missing_new_device_error:
           "Le nouvel appareil n'est pas importé dans la base de données Mercurius",
  device_single_dashboard_mbus_title: 'MBus',
  device_single_dashboard_tab: 'Tableau de Bord',
  device_single_offline_detailed_message: 'L\'appareil est "Hors Ligne"',
  device_single_online_detailed_message:
           'L\'appareil est "En Ligne", tout fonctionne correctement',
  device_single_error_detailed_message:
           'L\'appareil est en mode "Erreur"',
  device_single_status_documents_link: "Documents de l'Appareil",
  email_device_delete_subscription_title:
           "Fortes Data Management - Supprimer l'Abonnement",
  email_device_delete_subscription_api_status_title:
           "Réponse de l'API Maxitel :",
  email_device_delete_subscription_type_swapped_device_message:
           "L'abonnement de l'appareil est supprimé dans le module d'échange d'appareils",
  email_device_delete_subscription_type_clear_device_message:
           "L'abonnement de l'appareil est supprimé dans le module de réinitialisation de l'appareil",
  devices_form_subscription_id_field: "ID de l'Abonnement",
  device_single_rss_sim_status: 'Statut SIM',
  device_monitoring_error_list_table_title: 'Liste des Erreurs',
  device_monitoring_error_list_table_device: 'Appareil',
  device_monitoring_error_list_table_alarm_code: "Code d'Alarme",
  device_monitoring_error_list_table_start_date: 'Date de Début',
  device_monitoring_error_list_table_end_date: 'Date de Fin',
  device_monitoring_data_consumed_list_table_title:
           'Consommation de Données',
  device_monitoring_data_consumed_list_table_device: 'Appareil',
  device_monitoring_data_consumed_list_table_data_usage:
           'Utilisation des Données',
  device_monitoring_data_consumed_list_table_date: 'Date',
  device_monitoring_ota_history_list_table_title: 'Historique OTA',
  device_monitoring_ota_history_list_table_device: 'Appareil',
  device_monitoring_ota_history_list_table_status: 'Statut',
  device_monitoring_ota_history_list_table_date: 'Date',
  device_monitoring_device_status_history_title:
           "Historique de l'État de l'Appareil",
  device_monitoring_device_status_history_hr: 'Hr',
  device_monitoring_device_status_history_no_graph_data_error:
           'Le graphique est indisponible',
  device_monitoring_device_status_history_graph_loading_data:
           'Chargement du graphique',
  device_monitoring_firmware_history_title: 'Historique des Firmware',
  device_monitoring_device_firmware_history_graph_loading_data:
           'Chargement du diagramme en barres',
  device_monitoring_device_firmware_history_no_graph_data_error:
           'Le diagramme en barres est indisponible',
  sidebar_devices_monitoring: 'Surveillance des Appareils',
  device_monitoring_projects_counter_title: 'Compteur de Projets',
  device_monitoring_device_projects_counter_graph_loading_data:
           'Chargement du diagramme en barres',
  device_monitoring_device_projects_counter_no_graph_data_error:
           'Le diagramme en barres est indisponible',
  device_monitoring_users_counter_title: "Compteur d'Utilisateurs",
  device_monitoring_device_users_counter_graph_loading_data:
           'Chargement du diagramme en barres',
  device_monitoring_device_users_counter_no_graph_data_error:
           'Le diagramme en barres est indisponible',
  device_monitoring_sim_counter_title: 'Compteur de Données SIM',
  device_monitoring_sim_counter_exceeded_data_title: 'Dépassé',
  device_monitoring_sim_counter_in_package_data_title:
           'Dans la Limite du Forfait',
  device_monitoring_device_sim_counter_graph_loading_data:
           'Chargement du diagramme en barres',
  device_monitoring_device_sim_counter_no_graph_data_error:
           'Le diagramme en barres est indisponible',
  service_portal_login_in_dont_have_hiu_qr_code_checkbox_field:
           "Je n'ai pas de Code QR MTA",
  service_portal_login_hiu_autocomplete: "Unité d'Interface Thermique",
  device_monitoring_device_error_counters_title: "Compteurs d'Erreurs",
  device_monitoring_device_error_counters_error: 'Aucune donnée trouvée',
  device_monitoring_device_error_counters_no_graph_data_error:
           'Le diagramme circulaire est indisponible',
  device_monitoring_date_range_field: 'Plage de Dates',
  reporting_ota_details_retries_tab: 'Tentatives',
  device_monitoring_single_page_title: 'Surveillance des Appareils',
  device_monitoring_ota_history_list_table_fail_counter: 'Tentatives',
  device_monitoring_ota_history_list_table_user: 'Utilisateur',
  device_monitoring_ota_history_list_table_message: 'Message',
  device_monitoring_ota_history_list_table_old_firmware_version:
           'Ancienne Version du Firmware',
  device_monitoring_project_list_table_title: 'Projets',
  device_monitoring_firmware_list_table_title: 'Firmwares',
  device_monitoring_firmware_list_table_firmware_version: 'Firmware',
  device_monitoring_firmware_list_table_device: 'Appareil',
  device_monitoring_firmware_list_table_project: 'Projet',
  device_monitoring_firmware_list_table_saved_at: 'Date',
  monitoring_users_list_table_title: 'Utilisateurs',
  monitoring_3scale_users_list_table_title: 'Utilisateurs Three Scale',
  monitoring_3scale_users_filter_3scale_admin:
           'Administrateur Three Scale',
  monitoring_3scale_users_filter_3scale_client: 'Client Three Scale',
  client_single_users_table_role: 'Rôle',
  device_monitoring_device_status_list_table_title: "État de l'Appareil",
  navbar_languages_english: 'Anglais',
  navbar_languages_dutch: 'Néerlandais',
  navbar_languages_german: 'Allemand',
  device_single_device_settings_tab: 'Paramètres v2',
  device_single_device_settings_title: "Paramètres de l'Appareil v2",
  device_single_device_settings_secondary_device_type_field:
           "Type d'Appareil Secondaire",
  device_single_device_settings_base_topic_field: 'Sujet de Base',
  device_single_device_settings_baud_rate_uart_field:
           'Taux de Baud UART',
  device_single_device_settings_select_baud_rate_uart_field:
           'Sélectionner le Taux de Baud UART',
  device_single_device_settings_modbus_error_register_address_field:
           "Adresse de Registre d'Erreur Modbus",
  device_single_device_settings_modbus_error_register_value_field:
           "Valeur Attendue de Registre d'Erreur Modbus",
  device_single_device_settings_reboot_device_v2: 'Redémarrer',
  device_single_device_settings_send_button_v2: 'Envoyer',
  device_single_device_settings_message_forwarded:
           "Le message est transféré à l'appareil",
  device_single_device_settings_secondary_device_type_error:
           "Le Type d'Appareil Secondaire doit être entre 1 et 254",
  device_single_device_settings_base_topic_error:
           'La longueur du Sujet de Base doit être entre 5 et 50',
  device_single_device_settings_baud_rate_uart_error:
           'Veuillez sélectionner une option pour le Taux de Baud UART',
  device_single_device_settings_modbus_error_register_address_error:
           "L'adresse du registre d'erreur Modbus doit être entre 0 et 65535",
  device_single_device_settings_modbus_error_register_value_error:
           "La valeur attendue du registre d'erreur Modbus doit être entre 0 et 65535",
  device_monitoring_device_error_counters_loading_data:
           'Chargement du diagramme circulaire',
  diagnostic_formulas_form_diagnostic_formulas_name_de_field: 'Nom (DE)',
  diagnostic_formulas_name_de_required_form_error:
           'Le Nom (DE) est requis',
  diagnostic_formulas_form_description_de_field: 'Description (DE)',
  diagnostic_flows_form_diagnostic_flows_name_de_field: 'Nom (DE)',
  diagnostic_flows_name_de_required_form_error: 'Le Nom (DE) est requis',
  diagnostic_flows_form_description_de_field: 'Description (DE)',
  diagnostic_categories_form_diagnostic_categories_name_de_field:
           'Nom (DE)',
  diagnostic_categories_name_de_required_form_error:
           'Le Nom (DE) est requis',
  diagnostic_categories_form_description_de_field: 'Description (DE)',
  diagnostic_categories_table_name_de: 'Nom (DE)',
  diagnostic_flows_table_name_de: 'Nom (DE)',
  diagnostic_formulas_table_name_de: 'Nom (DE)',
  modules_form_module_name_de_field: 'Nom (DE)',
  modules_name_de_required_form_error: 'Le Nom (DE) est requis',
  modules_form_description_de_field: 'Description (DE)',
  modules_table_name_de: 'Nom (DE)',
  modules_table_description_de: 'Description (DE)',
  roles_form_role_name_de_field: 'Nom (DE)',
  roles_name_de_required_form_error: 'Le Nom (DE) est requis',
  roles_form_description_de_field: 'Description (DE)',
  roles_table_name_de: 'Nom (DE)',
  roles_table_description_de: 'Description (DE)',
  permissions_form_permission_name_de_field: 'Nom (DE)',
  permissions_form_description_de_field: 'Description (DE)',
  permissions_table_name_de: 'Nom (DE)',
  permissions_table_description_de: 'Description (DE)',
  notifications_form_title_de_field: 'Titre (DE)',
  notifications_form_description_de_field: 'Description (DE)',
  firmwares_name_de_required_form_error: 'Nom (DE)',
  firmwares_form_firmware_name_de_field: 'Le Nom (DE) est requis',
  navbar_languages_danish: 'Danois',
  firmwares_name_dk_required_form_error: 'Nom (DK)',
  firmwares_form_firmware_name_dk_field: 'Le Nom (DK) est requis',
  diagnostic_formulas_form_diagnostic_formulas_name_dk_field: 'Nom (DK)',
  diagnostic_formulas_name_dk_required_form_error:
           'Le Nom (DK) est requis',
  diagnostic_formulas_form_description_dk_field: 'Description (DK)',
  diagnostic_flows_form_diagnostic_flows_name_dk_field: 'Nom (DK)',
  diagnostic_flows_name_dk_required_form_error: 'Le Nom (DK) est requis',
  diagnostic_flows_form_description_dk_field: 'Description (DK)',
  diagnostic_categories_form_diagnostic_categories_name_dk_field:
           'Nom (DK)',
  diagnostic_categories_name_dk_required_form_error:
           'Le Nom (DK) est requis',
  diagnostic_categories_form_description_dk_field: 'Description (DK)',
  diagnostic_categories_table_name_dk: 'Nom (DK)',
  diagnostic_flows_table_name_dk: 'Nom (DK)',
  diagnostic_formulas_table_name_dk: 'Nom (DK)',
  modules_form_module_name_dk_field: 'Nom (DK)',
  modules_name_dk_required_form_error: 'Le Nom (DK) est requis',
  modules_form_description_dk_field: 'Description (DK)',
  modules_table_name_dk: 'Nom (DK)',
  modules_table_description_dk: 'Description (DK)',
  roles_form_role_name_dk_field: 'Nom (DK)',
  roles_name_dk_required_form_error: 'Le Nom (DK) est requis',
  roles_form_description_dk_field: 'Description (DK)',
  roles_table_name_dk: 'Nom (DK)',
  roles_table_description_dk: 'Description (DK)',
  permissions_table_name_dk: 'Nom (DK)',
  permissions_table_description_dk: 'Description (DK)',
  notifications_form_title_dk_field: 'Titre (DK)',
  notifications_form_description_dk_field: 'Description (DK)',
  device_monitoring_data_consumed_list_table_project: 'Projet',
  project_single_device_list_search_mbus_registers_by_project:
           'Recherche de Registres Mbus',
  project_single_fetch_mbus_registers_by_project_success_message:
           'Le message est transféré aux Appareils du Projet ##PROJECT_NAME## avec succès',
  project_single_fetch_mbus_registers_by_project_error_message:
           "Erreur du Serveur d'Intervalle",
  devices_list_diagnostic_filters_title: 'Filtres de Registres',
  devices_list_diagnostics_condition_modal_sensor_type_label:
           'Type de Capteur',
  devices_list_diagnostics_condition_modal_modbus_label:
           'Capteur Modbus',
  devices_list_diagnostics_condition_modal_mbus_label: 'Capteur Mbus',
  devices_list_diagnostics_condition_modal_formula_output_label:
           'Sortie de Formule',
  devices_list_diagnostics_condition_modal_meter_type_label:
           'Type de Compteur',
  devices_list_diagnostics_condition_modal_meter_type_label_default_option:
           'Sélectionner un Type de Compteur',
  devices_list_diagnostics_condition_modal_operator_label: 'Opérateur',
  devices_list_diagnostics_condition_modal_operator_option_1:
           'Inférieur',
  devices_list_diagnostics_condition_modal_operator_option_2:
           'Inférieur ou égal à',
  devices_list_diagnostics_condition_modal_operator_option_3: 'Égal à',
  devices_list_diagnostics_condition_modal_operator_option_4:
           'Supérieur',
  devices_list_diagnostics_condition_modal_operator_option_5:
           'Supérieur ou égal à',
  devices_list_diagnostics_condition_modal_operator_option_6:
           'Différent de',
  devices_list_diagnostics_condition_modal_register_label: 'Registre',
  devices_list_diagnostics_condition_modal_register_required_error:
           'Le Registre est requis',
  devices_list_diagnostics_send_message_modal_registers_label_default_option:
           'Sélectionner un Registre',
  devices_list_diagnostics_condition_modal_value_label: 'Valeur',
  devices_list_diagnostics_condition_modal_value_is_required_error:
           'La Valeur est requise',
  devices_list_diagnostics_condition_modal_operator_is_required_error:
           "L'Opérateur est requis",
  devices_list_diagnostics_condition_modal_meter_is_required_error:
           'Le Compteur est requis',
  devices_list_diagnostics_condition_modal_value_invalid_error:
           'Valeur invalide, veuillez vérifier',
  devices_list_diagnostics_condition_modal_save_button: 'Enregistrer',
  devices_list_diagnostics_condition_modal_cancel_button: 'Annuler',
  devices_list_diagnostics_condition_modal_add_button:
           'Ajouter plus de filtres',
  error_check_table_title: "Vérifications d'Erreur de l'Appareil",
  error_check_table_filter_search: 'Recherche',
  error_check_table_no_record_found: 'Aucun enregistrement trouvé',
  error_check_table_row_per_page: 'Lignes par page',
  error_check_table_created_at: 'Créé à',
  error_check_table_total_devices: 'Appareils au total',
  error_check_details_table_title:
           "Détails de la Vérification d'Erreur de l'Appareil",
  error_check_details_table_filter_search: 'Recherche',
  error_check_details_table_no_record_found:
           'Aucun enregistrement trouvé',
  error_check_details_table_row_per_page: 'Lignes par page',
  error_check_details_table_created_at: 'Créé à',
  error_check_details_table_device: 'Appareil',
  error_check_details_table_old_error: 'Ancienne Erreur',
  error_check_details_table_new_error: 'Nouvelle Erreur',
  error_check_details_table_status: 'Statut',
  error_check_details_table_status_success: 'Succès',
  error_check_details_table_status_failed: 'Échec',
  error_check_details_table_all_tab: 'Tous',
  error_check_details_table_success_tab: 'Succès',
  error_check_details_table_failure_tab: 'Échec',
  error_check_details_table_device_name: "Nom de l'Appareil",
  error_check_details_table_device_serial_number: 'Numéro de Série',
  sidebar_devices_error_check: 'Appareils avec Erreurs',
  error_check_details_table_bulk_export_file_name:
           'Liste de Détails des Appareils avec Erreurs',
  error_check_table_bulk_export_file_name:
           'Liste des Appareils avec Erreurs',
  device_single_registers_table_min_error:
           'La valeur doit être supérieure ou égale à min_value',
  device_single_registers_table_invalid_value_error:
           'Veuillez entrer un nombre valide',
  project_single_project_setting_tab: 'Project Setting',
  project_single_project_setting_mbus_registers_title: 'MBus Registers',
  project_single_project_setting_mbus_register_accumulated_heating_energy_title:
           'Accumulated heating energy Unit',
  project_single_project_setting_mbus_register_accumulated_heating_energy_success_message:
           'Accumulated heating energy unit is successfully updated',
  project_single_project_setting_mbus_register_accumulated_heating_energy_error_message:
           'Failed to update Accumulated heating energy unit',
  project_single_project_setting_select_mbus_register_accumulated_heating_energy_title:
           'Select Accumulated heating energy',
  project_single_project_setting_select_mbus_register_update_button:
           'Update',
  custom_popover_100_short_description: 'Heating state',
  custom_popover_101_short_description: 'Hot Water state',
  custom_popover_102_short_description: 'Preheat state',
  custom_popover_103_short_description: 'Thermal disinfection state',
  custom_popover_104_short_description: 'Cooling state',
  custom_popover_1802_short_description: 'TE Heating Element State',
  custom_popover_105_short_description: 'Alarm code',
  custom_popover_107_short_description: 'Hot water valve (M1)',
  custom_popover_108_short_description: 'Heating/cooling valve (M2)',
  custom_popover_109_short_description: 'Pump',
  custom_popover_110_short_description: '6-way-valve',
  custom_popover_113_short_description: 'Room thermostat input',
  custom_popover_114_short_description: 'OpenTherm Indicator',
  custom_popover_115_short_description: 'Appliance Type',
  custom_popover_200_short_description: 'Hot water flow rate',
  custom_popover_201_short_description: 'Hot water temperature',
  custom_popover_202_short_description: 'Primary supply',
  custom_popover_203_short_description: 'Primary return/Outdoor',
  custom_popover_205_short_description: 'Secondary supply',
  custom_popover_206_short_description: 'Secondary return',
  custom_popover_204_short_description: 'Calculated heating setpoint',
  custom_popover_207_short_description: 'Room Temperature (OpenTherm)',
  custom_popover_208_short_description: 'Room Setpoint (OpenTherm)',
  custom_popover_210_short_description: 'Secondary water pressure',
  custom_popover_800_short_description: 'Total system run time',
  custom_popover_801_short_description: 'Heating burn time',
  custom_popover_802_short_description: 'Cooling burn time',
  custom_popover_803_short_description: 'Hot water burn Time',
  custom_popover_804_short_description: 'Number of tappings',
  custom_popover_805_short_description: 'Hot water Volume',
  custom_popover_806_short_description: 'Power cuts',
  create_role_stepper_information_title: 'Information',
  create_role_stepper_client_title: 'Client',
  create_role_stepper_service_client_title: 'Service Client',
  create_role_stepper_project_title: 'Project',
  create_role_stepper_device_management_title: 'Device Management',
  create_role_stepper_commissioning_title: 'Commissioning',
  create_role_stepper_reporting_title: 'Reporting',
  create_role_stepper_general_title: 'General',
  roles_form_role_name_field: 'Name',
  roles_name_required_form_error: 'Name is required',
  roles_form_description_field: 'Description',
  create_role_stepper_back_button: 'Back',
  create_role_stepper_next_button: 'Next',
  create_role_stepper_finish_button: 'Finish',
  create_role_stepper_yes_label: 'Yes',
  create_role_stepper_no_label: 'No',
  create_role_stepper_step_2_client_view_all_users: 'View all users?',
  create_role_stepper_step_2_client_create_new_users:
           'Create new users?',
  create_role_stepper_step_2_client_edit_new_users:
           'Modify existing users like the role?',
  create_role_stepper_step_3_service_client_create_new:
           "Create a new 'service client'?",
  create_role_stepper_step_3_service_client_view_users:
           "View existing 'service client' users?",
  create_role_stepper_step_3_service_client_create_users:
           "Create new 'service client' users?",
  create_role_stepper_step_3_service_client_modify_users:
           "Modify 'service client' users like the role?",
  create_role_stepper_step_4_project_view: 'View projects?',
  create_role_stepper_step_4_project_create_new: 'Create new projects?',
  create_role_stepper_step_4_project_export_mbus_data:
           'Export energy meter data from projects?',
  create_role_stepper_step_5_device_view_single_page: 'Open a device?',
  create_role_stepper_step_5_device_view_single_page_setting_registers:
           'View settings?',
  create_role_stepper_step_5_device_edit_single_page_setting_registers:
           'Modify settings?',
  create_role_stepper_step_5_device_edit_single_page_manual_control:
           'Use manual control?',
  create_role_stepper_step_5_device_single_page_view_history:
           'View history?',
  create_role_stepper_step_5_device_single_page_view_meter_data:
           'View meter data?',
  create_role_stepper_step_5_device_single_page_use_diagnostics_module:
           'Use the diagnostics module?',
  create_role_stepper_step_5_device_single_page_information_and_delivery_set_data:
           'View information such as device info, address details, and delivery set data?',
  create_role_stepper_step_6_commissioning_module_put_devices_into_operation:
           'Commissioning module to put devices into operation?',
  create_role_stepper_step_6_commissioning_module_swapping:
           'Exchange devices (transfer data from old device to new device)?',
  create_role_stepper_step_7_reporting_audit:
           'The logbook that contains all messages, errors, and actions on devices?',
  create_role_stepper_step_7_reporting_swapped_devices:
           'Logbook of exchanged devices?',
  create_role_stepper_step_7_reporting_error_devices:
           'Logbook of devices with faults?',
  create_role_stepper_step_7_reporting_bulk_registers_update:
           'Results of group changes?',
  create_role_stepper_step_8_view_dashboard:
           'Dashboard all access and sidebar device counters',
  create_role_stepper_step_8_general_create_role: 'Creating roles?',
  create_role_stepper_question_required_error:
           'One option is required to proceed',
  project_single_project_setting_device_v2_settings_title:
           'Device V2 Settings',
  project_single_device_settings_secondary_device_type_field:
           'Secondary Device Type',
  project_single_project_setting_base_topic_field: 'Base Topic',
  project_single_device_settings_baud_rate_uart_field: 'Baud Rate UART',
  project_single_device_settings_select_baud_rate_uart_field:
           'Select Baud Rate UART',
  project_single_device_settings_modbus_error_register_address_field:
           'Modbus error register address',
  project_single_device_settings_modbus_error_register_value_field:
           'Modbus expected error register value',
  project_single_project_setting_device_v2_send_button: 'Send',
  project_single_project_setting_base_topic_success_message:
           'Base Topic is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
  project_single_project_setting_secondary_device_type_success_message:
           'Secondary Device Type is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
  project_single_project_setting_reboot_message:
           'Device Reboot Message is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
  project_single_project_setting_baut_rate_uart_success_message:
           'Device Baut Rate UART Message is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
  project_single_project_setting_modbus_expected_error_register_value_success_message:
           'Device Modbus Expected Error Register Value Message is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
  project_single_project_setting_modbus_error_register_address_success_message:
           'Device Reboot Message is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
  docs_third_tab_video_instruction: 'Video Instruction',
  docs_third_tab_video_instruction_title: 'Video Instruction',
  custom_popover_106_short_description: 'Software version',
  custom_popover_106_long_description: 'Software version',
  EXPORT_MBUS_METER_SERIAL_NUMBER: 'Serial number meter',
  navbar_languages_french: 'French',
  diagnostic_formulas_form_diagnostic_formulas_name_fr_field:
           'Name (FR)',
  diagnostic_formulas_name_fr_required_form_error:
           'Name (FR) is required',
  diagnostic_formulas_form_description_fr_field: 'Description (FR)',
  diagnostic_flows_form_diagnostic_flows_name_fr_field: 'Name (FR)',
  diagnostic_flows_name_fr_required_form_error: 'Name (FR) is required',
  diagnostic_flows_form_description_fr_field: 'Description (FR)',
  diagnostic_categories_form_diagnostic_categories_name_fr_field:
           'Name (FR)',
  diagnostic_categories_name_fr_required_form_error:
           'Name (FR) is required',
  diagnostic_categories_form_description_fr_field: 'Description (FR)',
  modules_form_module_name_fr_field: 'Name (FR)',
  modules_name_fr_required_form_error: 'Name (FR) is required',
  modules_form_description_fr_field: 'Description (FR)',
  roles_form_role_name_fr_field: 'Name (FR)',
  roles_name_fr_required_form_error: 'Name (FR) is required',
  roles_form_description_fr_field: 'Description (FR)',
  permissions_form_permission_name_fr_field: 'Name (FR)',
  permissions_form_description_fr_field: 'Description (FR)',
  firmwares_form_firmware_name_fr_field: 'Name (FR)',
  firmwares_name_fr_required_form_error: 'Name (FR) is required',
  notifications_form_title_fr_field: 'Name (FR)',
  notifications_form_description_fr_field: 'Description (FR)',
  notifications_title_dk_required_form_error: 'Name (DK) is required',
  notifications_title_fr_required_form_error: 'Name (FR) is required',
  notifications_title_de_required_form_error: 'Name (DE) is required',
  notifications_description_dk_required_form_error:
           'Description (DK) is required',
  notifications_description_de_required_form_error:
           'Description (DE) is required',
  notifications_description_fr_required_form_error:
           'Description (FR) is required',
  client_single_error_devices_bread_crumb: 'Error Devices',
  service_client_single_error_devices_bread_crumb: 'Error Devices',
  devices_list_diagnostics_condition_modal_fetch_and_filter_button:
           'Fetch & Filter',
  diagnostic_register_drawer_selected_devices_title: 'Selected Devices',
  diagnostic_register_drawer_fetch_and_filter_message_1:
           'Fetching devices data',
  diagnostic_register_drawer_fetch_and_filter_message_2:
           'Database refresh',
  diagnostic_register_drawer_fetch_and_filter_message_3:
           'Filtering devices',
  devices_bulk_mbus_download_selected_devices_title: 'Devices Selected',
  custom_popover_100_long_description: 'Heating state',
  custom_popover_101_long_description: 'Hot Water state',
  custom_popover_102_long_description: 'Preheat state',
  custom_popover_103_long_description: 'Thermal disinfection state',
  custom_popover_104_long_description: 'Cooling state',
  custom_popover_1802_long_description: 'TE Heating Element State',
  custom_popover_107_long_description: 'Hot water valve (M1)',
  custom_popover_108_long_description: 'Heating/cooling valve (M2)',
  custom_popover_109_long_description: 'Pump',
  custom_popover_110_long_description: '6-way-valve',
  custom_popover_113_long_description: 'Room thermostat input',
  custom_popover_114_long_description: 'OpenTherm Indicator',
  custom_popover_115_long_description: 'Appliance Type',
  custom_popover_200_long_description: 'Hot water flow rate',
  custom_popover_201_long_description: 'Hot water temperature',
  custom_popover_202_long_description: 'Primary supply',
  custom_popover_203_long_description: 'Primary return/Outdoor',
  custom_popover_205_long_description: 'Secondary supply',
  custom_popover_206_long_description: 'Secondary return',
  custom_popover_204_long_description: 'Calculated heating setpoint',
  custom_popover_207_long_description: 'Room Temperature (OpenTherm)',
  custom_popover_208_long_description: 'Room Setpoint (OpenTherm)',
  custom_popover_210_long_description: 'Secondary water pressure',
  custom_popover_800_long_description: 'Total system run time',
  custom_popover_801_long_description: 'Heating burn time',
  custom_popover_802_long_description: 'Cooling burn time',
  custom_popover_803_long_description: 'Hot water burn Time',
  custom_popover_804_long_description: 'Number of tappings',
  custom_popover_805_long_description: 'Hot water Volume',
  custom_popover_806_long_description: 'Power cuts',
  project_single_project_setting_mbus_hourly_logs_title:
           'Mbus Hourly Logs',
  project_single_project_setting_mbus_hourly_logs_field:
           'Fetch Mbus Hourly',
  project_single_project_setting_mbus_hourly_logs_update_button:
           'Update',
  project_single_project_setting_mbus_hourly_logs_success_message:
           'Mbus Hourly logs are successfully set for the project',
  project_single_project_setting_mbus_hourly_logs_error_message:
           'Failed to update Mbus Hourly logs for the project',
  sidebar_mbus_reporting: 'Mbus Reporting',
  mbus_reporting_table_device: 'Device',
  mbus_reporting_table_project: 'Project',
  mbus_reporting_table_created_at: 'Created At',
  mbus_reporting_table_viewed_by: 'Viewed By',
  mbus_reporting_table_viewed_at: 'Viewed At',
  mbus_reporting_table_firmware: 'Firmware',
  mbus_reporting_table_no_record_found: 'No Records Found',
  mbus_reporting_table_all_filter: 'All',
  mbus_reporting_table_read_filter: 'Read',
  mbus_reporting_table_unread_filter: 'Unread',
  mbus_reporting_table_row_per_page: 'Rows per page',
  mbus_reporting_table_title: 'Mbus Reporting',
  mbus_reporting_anomaly_table_title: 'Mbus Anomalies',
  mbus_reporting_anomaly_table_difference: 'Difference',
  mbus_reporting_anomaly_table_detection: 'Detection',
  mbus_reporting_anomaly_table_mbus_meter_type: 'Meter',
  mbus_reporting_anomaly_table_mbus_serial_number: 'Mbus Serial Number',
  mbus_reporting_anomaly_table_mbus_old_value: 'Old Value',
  mbus_reporting_anomaly_table_mbus_register: 'Register',
  mbus_reporting_anomaly_table_mbus_reason: 'Reason',
  mbus_reporting_anomaly_table_mbus_fail_reason: 'Greater than',
  mbus_reporting_anomaly_table_mbus_suspicious_reason: 'Lies in between',
  mbus_reporting_anomaly_table_fail: 'Fail',
  mbus_reporting_anomaly_table_suspicious: 'Suspicious',
  mbus_reporting_table_bulk_view_reports_success_message:
           'Mbus Reporting Messages are successfully viewed',
  mbus_reporting_table_bulk_view_reports_error_message:
           'Please select Mbus Reporting records to proceed',
  mbus_reporting_table_bulk_view_button: 'Bulk View',
  mbus_reporting_table_view_button: 'View',
  mbus_reporting_table_filters_project_field: 'Project',
  mbus_reporting_table_filters_device_name_field: 'Device',
  mbus_reporting_table_filters_start_date: 'Start Date',
  mbus_reporting_table_filters_end_date: 'End Date',
  mbus_reporting_table_filters_firmware: 'Firmware',
  mbus_reporting_table_filters_mbus_serial_number: 'Mbus Serial Number',
  mbus_reporting_table_filters_viewed_by: 'Viewed By',
  business_situation_measured_values_209: 'Outdoor Temperature',
  custom_popover_209_short_description: 'Outdoor Temperature',
  custom_popover_209_long_description: 'Outdoor Temperature',
  appliance_types_set_registers_table_dashboard: 'Dashboard',
  appliance_types_set_registers_table_dashboard_error_message:
           'Minimum 0 and Maximum 6 Dashboard Registers are allowed for device dashboard',
  sidebar_missing_hourly_reporting: 'Mbus Missing Data Report',
  missing_hourly_reporting_table_title:
           'Mbus Hourly Missing Data Reporting',
  mbus_hourly_missing_reporting_table_filters_project_field: 'Project',
  mbus_hourly_missing_reporting_table_filters_device_name_field:
           'Device',
  mbus_hourly_missing_reporting_table_filters_firmware_field: 'Firmware',
  mbus_hourly_missing_reporting_table_last_seen: 'Mbus Message At',
  mbus_hourly_missing_reporting_table_created_at: 'Created At',
  mbus_hourly_missing_reporting_table_device: 'Device',
  mbus_hourly_missing_reporting_table_project: 'Project',
  mbus_hourly_missing_reporting_table_firmware: 'Firmware',
  mbus_hourly_missing_reporting_table_no_record_found:
           'No Records Found',
  mbus_hourly_missing_reporting_table_row_per_page: 'Rows per page',
  diagnostic_flows_form_loading_text: 'Loading ...',
  device_monitoring_device_frequency_history_counters_title: 'Device Anomalies Frequency',
  device_monitoring_device_frequency_history_counters_error: 'No data found',
  device_monitoring_device_frequency_history_counters_no_graph_data_error: 'The piechart is unavailable',
  device_monitoring_device_frequency_history_counters_loading_data: 'Loading ...',
  device_monitoring_project_frequency_history_counters_title: 'Project Anomalies Frequency',
  device_monitoring_project_frequency_history_counters_error: 'No data found',
  device_monitoring_project_frequency_history_counters_no_graph_data_error: 'The piechart is unavailable',
  device_monitoring_project_frequency_history_counters_loading_data: 'Loading ...',
  device_monitoring_firmware_anomalies_history_title: 'Firmware Anomalies Frequency',
  device_monitoring_device_firmware_anomalies_history_graph_loading_data: 'The barchart is unavailable',
  device_monitoring_device_firmware_anomalies_history_no_graph_data_error: 'Loading ...',
  devices_device_id: 'Device Id',
  device_monitoring_device_frequency_details_table_title: 'Device Anomalies Frequency',
  device_monitoring_device_frequency_details_table_firmware: 'Firmware',
  device_monitoring_device_frequency_details_table_device: 'Device',
  device_monitoring_device_frequency_details_table_project: 'Project',
  device_monitoring_device_frequency_details_table_saved_at: 'Date',
  device_monitoring_device_frequency_details_table_startup_messages_count: 'Start up messages count',
  device_monitoring_project_frequency_details_table_title: 'Project Anomalies Frequency',
  device_monitoring_project_frequency_details_table_project: 'Project',
  device_monitoring_project_frequency_details_table_devices_count: 'Devices Count',
  device_monitoring_project_frequency_details_table_startup_messages_count: 'Start up messages count',
  device_monitoring_firmware_frequency_details_table_title: 'Firmware Anomalies Frequency',
  device_monitoring_firmware_frequency_details_table_firmware: 'Firmware',
  device_monitoring_firmware_frequency_details_table_startup_messages_count: 'Start up messages count',
  client_single_users_list_reset_and_send_email_button: 'Reset Password',
  user_reset_password_form_success: 'User password is updated successfully',
  users_reset_password_modal_title: 'Reset Password',
  users_reset_password_modal_subtitle: 'Do you want to reset password for this user and send email?',
  users_reset_password_modal_cancel_button: 'Close',
  users_reset_password_modal_reset_password_button: 'Reset and Send Email',
  reset_password_subject:
    'Fortes Data Management - Reset Password',
  clients_form_is_2fa_enabled_field:
           'Enable Two-Factor Authentication (2FA)',
  service_clients_form_is_2fa_enabled_field:
           'Enable Two-Factor Authentication (2FA)',
  two_factor_authentication_qr_verified_title:
           'Two-Factor Authentication (2FA)',
  two_factor_authentication_qr_unverified_title:
           'Setup Two-Factor Authentication (2FA)',
  two_factor_authentication_permission_error: '',
  two_factor_authentication_description_step_1:
           'Install one of the following applications on your mobile:',
  two_factor_authentication_description_step_2:
           'Open the application and scan the QR Code:',
  two_factor_authentication_description_step_2_rescan:
           'Regenerate QR Code',
  two_factor_authentication_description_step_3:
           'Enter the one-time code provided by the application and click Submit to finish the setup.',
  two_factor_authentication_form_otp_field: 'OTP Code',
  two_factor_authentication_form_otp_characters_limit_error:
           'OTP must be 6 characters',
  two_factor_authentication_form_otp_required_error: 'OTP is required',
  two_factor_authentication_form_incorrect_otp_error:
           'Invalid OTP, please retry again with a new OTP',
  two_factor_authentication_form_success:
           'OTP is successfully authenticated',
  two_factor_authentication_submit_button: 'Submit',
  client_single_users_list_enable_2fa_qr_code_button:
           'Enable Two-Factor Authentication (2FA) QR Code',
  service_client_single_users_list_enable_2fa_qr_code_button:
           'Enable Two-Factor Authentication (2FA) QR Code',
  users_enable_2fa_qr_code_modal_title:
           'Enable Two-Factor Authentication (2FA) QR Code',
  users_enable_2fa_qr_code_modal_subtitle:
           'Are you sure you want to enable Two-Factor Authentication (2FA) QR Code for this user?',
  users_enable_2fa_qr_code_modal_cancel_button: 'Cancel',
  users_enable_2fa_qr_code_modal_enable_2fa_qr_code_button:
           'Enable Two-Factor Authentication (2FA) QR Code',
  client_single_users_list_enable_2fa_qr_code_success_message:
           'Two-Factor Authentication (2FA) QR Code is successfully enabled',
  client_single_2fa_label: 'Two-Factor Authentication (2FA)',
  enabled_2fa_label: 'Enabled',
  disabled_2fa_label: 'Disabled',
  service_client_single_2fa_label: 'Two-Factor Authentication (2FA)',
  client_single_users_table_last_access: 'Last Seen',
  service_client_single_users_table_last_access: 'Last Seen',
  projects_form_header: 'Project Information',
  projects_commissioning_form_header: 'Project Commissioning',
  projects_commissioning_form_export_button: 'Export Devices',
  projects_commissioning_form_export_file_name:
           'Project Commissioning Export',
  projects_commissioning_form_import_button: 'Import Devices',
  projects_commissioning_form_import_message:
           '<strong>Steps to Import Devices</strong> <ol> <li>Download the Project Commissioning File Template from green Export Devices Button.</li> <li>Update the file and don\'t change the header column names, in case they\'re changed. The file import will fail.</li> <li>Import the file and changes will be updated.</li> </ol>',
  projects_commissioning_form_import_success_message:
           'Devices data is successfully commissioned',
  commissoning_device_stepper_step_1_scan_qr_serial_number_title:
           'Scan QR serial number ',
  commissoning_device_stepper_step_2_validate_project_setting_title:
           'Validate Modbus and M-Bus',
  commissoning_device_stepper_step_3_update_address_information_title:
           'Update Address',
  commissoning_device_stepper_step_4_hiu_commissioning_title:
           'HIU Commissioning',
  commissoning_device_stepper_step_5_preview_and_submit_title:
           'Preview and Submit',
  commissoning_form_dont_have_serial_number_checkbox:
           "Don't have Serial QR Code?",
  commissoning_form_back_button: 'Previous',
  commissoning_form_next_button: 'Next',
  commissoning_form_finish_button: 'Finish',
  commissoning_form_device_serial_is_missing_error:
           'Serial Number is required',
  commissoning_form_modbus_mbus_validation_error:
           'Validation failed, do you want to proceed anyway?',
  commissoning_form_stepper_step_2_validation_project_setting_title:
           'Project Settings',
  commissoning_form_stepper_step_2_validation_current_setting_title:
           'Device Settings',
  commissoning_form_stepper_step_2_validation_validate_to_view:
           'Validate to view details',
  commissoning_form_stepper_step_2_validation_modbus_title: 'Modbus',
  commissoning_form_stepper_step_2_validation_mbus_title: 'M-Bus',
  commissoning_form_stepper_step_2_validation_modbus_connected_title: 'Connected',
  commissoning_form_stepper_step_2_validation_modbus_not_connected_title: 'Not Connected',
  commissoning_form_stepper_step_2_validation_mbus_meters_title: 'meter(s)',
  project_single_project_setting_modbus_and_mbus_validation_form_title: 'Modbus and Mbus Setting',
  commissoning_form_stepper_step_2_validation_is_modbus_connected: 'Is Modbus Connected',
  commissoning_form_stepper_step_2_validation_mbus_meters_select_field: 'M-Bus Meters',
  commissoning_form_stepper_step_2_validation_mbus_meters_select_option_field: 'Select M-Bus Meters',
  commissoning_form_stepper_step_2_validation_mbus_meters_validate_button: 'Validate',
  commissoning_form_upload_file_type_error: 'Only XLSX files are allowed.',
  commissoning_form_upload_file_invalid_column_names_error: 'Invalid column ##COLUMNS##, please don\'t change the column names',
  commissoning_form_upload_file_size_error: 'File size should be less than 5 MB.',
  commissoning_form_uploading_text: 'Uploading...',
  project_single_project_setting_mbus_reporting_form_title: 'Mbus Reporting Setting',
  project_single_project_setting_mbus_hourly_missing_data_reporting_value_field: 'Mbus Hourly Missing Data Reporting',
  project_single_project_setting_mbus_anomaly_reporting_value_field: 'Mbus Anomaly Reporting',
  project_single_project_setting_mbus_reporting_form_button: 'Update',
  project_single_project_setting_mbus_reporting_success_message:
           'Mbus Reporting data is successfully set for the project',
  project_single_project_setting_mbus_reporting_error_message:
           'Failed to update Mbus Reporting data for the project',
  commissoning_form_upload_file_incorrect_serial_number_error: 'Incorrect Serial Number(s) found for the project commissioning',
  commissoning_form_upload_file_incorrect_devices_data_error: 'Incorrect Device(s) data found for the project commissioning',
  commissoning_form_upload_file_empty_sheet_error: 'Project Commissioning file is empty',
  commissoning_form_upload_success_message: 'Project Commissioning is successfully completed',
  commissioning_validation_failed_subject: 'Fortes Energy - Device Commissioning Wizard Validation',
  commissoning_form_stepper_step_2_validation_mbus_meters_loading_title: 'Mbus meters check takes upto 30 - 60 seconds',
  commissoning_form_stepper_step_2_validation_mbus_meters_retries_label: 'Retries',
  commissoning_form_stepper_step_2_validation_mbus_meters_retries_button: 'Retry',
  error_docs_table_folder_name: 'Folder Name',
  error_docs_table_file_name: 'File Name',
  error_docs_no_record_found: 'No Records Found',
  error_docs_title: 'Document Errors',
  error_docs_filter_search: 'Search',
  error_docs_total_records: 'Total Records',
  error_docs_link: 'Document Errors',
  docs_third_tab_montage_instructie: 'Montage instructie',
  device_single_documentation_instructie_pomp_button: 'Instructie Pomp',
};
