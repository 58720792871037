/* eslint-disable import/extensions */
// import { isUndefined } from 'lodash';
import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);
// Error pages
const DocsNotFound = Loadable(lazy(() => import('./pages/DocsNotFound')));

// Documents page
const DeviceDocsDirectory = Loadable(lazy(() => import('src/components/docs/DeviceDocs/DeviceDocsDirectory')));
const DeviceDocsOverview = Loadable(lazy(() => import('src/components/docs/DeviceDocs/DeviceDocsOverview')));
const DeviceDocsErrorOverview = Loadable(lazy(() => import('src/components/docs/DeviceDocs/DeviceDocsErrorOverview')));

// eslint-disable-next-line import/no-mutable-exports
const routes: PartialRouteObject[] = [
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '/document-error',
        element: (
          <GuestGuard>
            <DeviceDocsErrorOverview />
          </GuestGuard>
        )
      },
      {
        path: '/',
        element: (
          <GuestGuard>
            <DeviceDocsDirectory />
          </GuestGuard>
        )
      },
      {
        path: '/:articleId',
        element: (
          <GuestGuard>
            <DeviceDocsOverview />
          </GuestGuard>
        )
      },
      {
        path: '*',
        element: <DocsNotFound />
      }
    ]
  }
];

export default routes;
